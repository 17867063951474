import React, { useState } from "react";
import logobytefaze from "../../assets/WebpImages/bytedark.webp";
import { Link } from "react-router-dom";
import axios from "axios";

import Poploader from "../pages/Poploader";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const Forgot = () => {
  const [email, setEmail] = useState("");

  const [msg, setMsg] = useState("");

  const submit = async (e) => {
    // buttonDisable
    // Loader

    setMsg(<Poploader />);
    e.preventDefault();

    try {
      const res = await axios.post(
        "https://www.bytefaze-backend.onrender.com/api/v1/user/forgot-password",
        // reset-password/:token
        { email },
        {
          accept: "application/json",
        }
      );
      setMsg(res.data.message);
      // Response succes :  Disable Loader
    } catch (error) {
      // button will be Visible Again

      setMsg("something is wrong");
      setTimeout(() => {
        setMsg("");
      }, 4000);
    }
  };
  return (
    <div>
      <section className="bg-light py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
              <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <div className="text-center mb-3">
                    <a href="/#">
                      <img 
                        src={logobytefaze}
                        alt="BootstrapBrain Logo"
                        width="200"
                        height="140"
                      />
                    </a>
                  </div>
                  <h2 className="fs-6 fw-normal text-center text-secondary mb-4">
                    Provide the email address associated with your account to
                    recover your password.
                  </h2>
                  <form action="/#">
                    <div className="row gy-2 overflow-hidden">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            onChange={(e) => setEmail(e.target.value)}
                            value={email || ""}
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="name@example.com"
                            required
                          />
                          <label for="email" className="form-label">
                            Email
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-grid my-3">
                          <button
                            disabled={msg ? true : false}
                            onClick={submit}
                            className={`border btn-lg ${msg ? "btn2" : "btn"} `}
                            type="submit"
                          >
                            Reset Password
                          </button>
                        </div>
                        <div className="text-center">{msg}</div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex gap-2 justify-content-between">
                          <Link
                            to="/login"
                            className="link-primary text-decoration-none"
                          >
                            Log In
                          </Link>
                          <Link
                            to="/signup"
                            className="link-primary text-decoration-none"
                          >
                            Register
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Forgot;
