import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import testimonial from "../../assets/testimonials/clients.png";
import bharat from '../../assets/testimonials/bharat-industries.png'
import hariom from '../../assets/testimonials/hari-om-steel.jpg'
import { Link } from 'react-router-dom';

// import "../../Styles/Footer.css"

const WebTestimonials = () => {
  // const [display, setDisplay] = useState(true);
  // const [width, setWidth] = useState(600);

  const settings = {
    autoplay: true,
    autoplaySpeed: 1200,
    arrow: true,
    pauseOnFocus: false,
    pauseOnHover: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const testimonialData = {
    testimonial1: {
      name: "Vishal Solanki",
      occupation: "Retail & Ecommerce",
      testimony:
        `"professional developers in this company bytefaze web solutions.
And I had created responsive website with bytefaze web solutions.
Thank you"`,
      image: testimonial,
      
    },
    testimonial2: {
      name: "Dr. Fahim Pathan",
      occupation: "Healthcare",
      testimony: `"Our new website has been a game-changer for patient engagement. The intuitive design and easy navigation have made it simpler for our patients to find information and book appointments. Highly recommend their services!"`,
      image: testimonial,
      
    },
    testimonial3: {
      name: "Dhaval Parmar",
      occupation: "Finance",
      testimony: `"Their expertise in web development helped us create a secure and professional site that reflects our commitment to excellence. The project was delivered on time and within budget"`,
      image: testimonial,
      
    },
    testimonial4: {
      name: "Rahul Nigam",
      occupation: "Food & Beverages",
      testimony: `
      "Our website now perfectly captures the essence of our brand thanks to their responsive and dynamic design services. Customers can easily browse our menu, place orders, and stay updated on our latest offerings. The user-friendly interface and stunning visuals have truly enhanced our online presence."`,
      image: testimonial,
     
    },
    testimonial44: {
        name: "Rex Christian",
        occupation: "Manufacturing",
        testimony: `
        "I recently partnered with Bytefaze to create my new website, and I'm absolutely thrilled with the outcome! The entire process, from our initial meeting to the final launch, was a breeze thanks to the Bytefaze team's professionalism, responsiveness, and undeniable talent."`,
        image: testimonial,
      },
      testimonial5: {
        name: "Vikram Vanjara",
        occupation: "Chemical Industry",
        testimony: `
        "The team delivered a responsive and dynamic website that meets our complex needs. The new site is not only visually appealing but also highly functional, with an intuitive layout that makes it easy for clients to access detailed product information and technical specifications."`,
        image: testimonial,
      },
      testimonial6: {
        name: "Chirag Shah",
        occupation: "Hospitality",
        testimony: `
        "The website they developed for us is stunning and easy to navigate. Our bookings have increased since the launch, and our guests frequently compliment the site's design and functionality"`,
        image: testimonial,
      },
      testimonial7: {
        name: "Bharat Industries",
        occupation: "Manufacturing",
        testimony: `
        "Our experience with the website design service was outstanding. The team of Bytefaze is truly grasped our vision and transformed it into reality with both creativity and professionalism. We were particularly impressed by their meticulous attention to detail and dedication to fulfilling our requirements, leading to a beautiful website that has greatly improved our online presence.."`,
        image: bharat,
      },
      testimonial7: {
        name: "Hari Om Steel",
        occupation: "Manufacturing",
        testimony: `
        "I had a very specific vision for my website, and Bytefaze was able to bring it to life. Their affordable website development services exceeded my expectations. The design is not only visually appealing but also functional and easy to navigate. I'm so grateful for their expertise and professionalism"`,
        image: hariom,
      }

  };
  return (

    <div  className='py-4'>


      <div className="container overflow-hidden px-4 " >
        <div className="slider-container ">
          <h3 className='mb-5 text-center  ' style={{ color: "var(--primary-color)" }}>What Our Clients Say</h3>

          <Slider {...settings} >

            {Object.keys(testimonialData).map(key => {
              const { name, occupation, testimony, image, linkedin, instagram, github } = testimonialData[key];
              return (
                <div className="col-md-4 testi-container border" key={key} >
                  <div className=" text-center ">
                    <div className="" >
                      {/* <h5 className="card-title">{name}</h5>
                <p className="card-text">{occupation}</p> */}
                      <p className="card-text">{testimony}</p>
                    </div>
                    <img 
                      src={(`${image}`)}
                      className="card-img-top mx-auto mt-3 mb-3"
                      style={{ width: "80px", height: "80px", borderRadius: "100%" }}
                      alt={name}
                    />
                    <h5 className="card-title">{name}</h5>
                    <span className='px-3 d-flex gap-2 justify-content-center'>
                      <div>

                        {
                          linkedin ? <Link to={linkedin ? linkedin : ""} target='_blank' className='top-para' > <i className="ri-linkedin-box-fill h5"></i></Link> : ""
                        }
                      </div>
                      <div>
                        {
                          instagram ? <Link to={instagram ? instagram : ""} target='_blank' className='top-para' ><i className="ri-instagram-fill h5"></i></Link> : ""
                        }

                      </div>
                      <div>
                        {
                          github ? <Link to={github ? github : ""} target="_blank" className='top-para' > <i className="ri-github-fill h5"></i></Link> : ""}

                      </div>
                    </span>
                    <p className="card-text">{occupation}</p>




                  </div>

                </div>
              );
            })}

          </Slider>
        </div>
      </div>
    </div>
  );
};

export default React.memo(WebTestimonials)