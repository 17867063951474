import React, { useEffect } from "react";
//import { LazyLoadImage } from "react-lazy-load-image-component";
import Layout from "../layout/Layouts";
import Websitefaq from "../FAQs/Websitefaq";
// import "../../Styles/Services.css";
// import table from "../../assets/WebpImages/table.webp";
import uiuxImg from "../../assets/WebpImages/uiux-web-design.webp";
import res from "../../assets/WebpImages/responsive-website.webp";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import keyElement from "../../assets/WebpImages/keyelement-website-designing.webp";
import builder from "../../assets/WebpImages/website-builder.webp"
import webmaintain from "../../assets/WebpImages/website-maintenance.webp"
import web2 from "../../assets/WebpImages/web2.webp";
import web3 from "../../assets/WebpImages/web32.webp";
import web4 from "../../assets/WebpImages/web4.webp";
import { Link, useLocation } from "react-router-dom";
import Testimonials from "../pages/WebTestimonials.jsx";
import {
  FaLaptop,
  FaBusinessTime,
  FaCogs,
  FaMobileAlt,
  FaGlobe,
  FaShoppingCart,
  FaExchangeAlt,
  FaFileAlt,
  FaUser,
} from "react-icons/fa";

const designTypes = [
  { icon: <FaLaptop />, title: "Responsive Website Design", link: "/responsive-website" },
  { icon: <FaBusinessTime />, title: "Business Website Designing" },
  { icon: <FaCogs />, title: "Custom Web Design", link: "/custom-web-design" },
  { icon: <FaMobileAlt />, title: "Web Design For Startups", link: "/blog/embracing-the-future-of-web-design-in-ahmedabad-with-bytefaze-web-solution/#web-design-for-startups" },
  { icon: <FaGlobe />, title: "Static Website Designing", link: "/static-web-design-development-services" },
  {
    icon: <FaShoppingCart />,
    title: "E-commerce Website Designing",
    link: "/ecommerce-website",
  },
  { icon: <FaExchangeAlt />, title: "Dynamic Website Designing" },
  { icon: <FaFileAlt />, title: "Landing Page Website Designing", link: "/landing-page-website-designing" },
  { icon: <FaUser />, title: "Portfolio Web Design" },
];
const WebsiteDesign = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); // Remove the '#' to get the id
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    <Layout
      title={"Website Designing Company in Ahmedabad | Web Design Services | Bytefaze, India"}
      keywords={
        "Website Designing, Online Website Designing, Responsive Website Designing, UI UX Web Design, E Commerce Website Design, Web Designign Ahmedabad, Web Design Ahmedabad, Best Web Designer, Professional Website Design, Top Website Development Services, Web Design Services, Web Designing Company, Web Design Agency in Ahmedabad"
      }
      description={
        "Bytefaze is a website designing company in Ahmedabad, India, offering best web design services. We develop good quality websites have visually appealing, user-friendly and responsive design optimized for search engines and conversion. Make your custom website today"
      }
      canonical={"https://www.bytefaze.com/website-designing"}
      ldName={"Website Designing"}
      ldImage={webdesign}
      serviceType={"Website Designing Service"}
    >
      <section className="container my-5">
        <div className="row align-items-center">
          <div className="col-lg-6 text-center text-lg-start order-lg-1 order-2">
            <h1 className="fw-bold mb-3">
              Website Designing Company in Ahmedabad
            </h1>
            <p itemscope itemtype="https://schema.org/BreadcrumbList">
              <span itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                <a itemprop="item" href="/">
                  <span itemprop="name">Home</span>
                </a>
                <meta itemprop="position" content="1" />
              </span> /
              <span itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                <a itemprop="item" href="/what-we-do">
                  <span itemprop="name">Services</span>
                </a>
                <meta itemprop="position" content="2" />
              </span> /
              <span itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                <a itemprop="item" href="/website-designing">
                  <span itemprop="name">Web Design</span>
                </a>
                <meta itemprop="position" content="3" />
              </span>
            </p>
            <p className="col-12  mx-auto mx-lg-0 top-para">Create customized websites with Bytefaze, a creative web designing company in Ahmedabad, India. We deliver responsive, user-friendly good web design to make your online presence truly stand out!
            </p>
            {/*<p className="col-12  mx-auto mx-lg-0 top-para"> Create customized website in Ahmedabad with a user-friendly, responsive design. Partner with India’s No. 1 Website Designing Company today      </p>  */}
            <div className="hero-buttons mt-4 text-center d-flex flex-column flex-lg-row align-items-center">
              <a href="/contact-us" className="button-33 mb-lg-0 col-12 col-lg-3">Send Enquiry </a>
              <a href="tel:+91 8469717272" className="button-33 mx-lg-3 text-center col-12 col-lg-4">Call Now </a>
            </div>
          </div>
          <div className="col-lg-6 mb-4 mb-lg-0 order-lg-2 order-1">
            <img
              src={web2}
              alt="Web Design"
              title="Website Designing Company in Ahmedabad"
              className="img-fluid rounded gif-transparent "
            />
          </div>
        </div>
      </section>

      <section>
        <h2 className="text-center mt-5 top-para">
          Best Website Designer in Ahmedabad
        </h2>
        <hr
          style={{
            borderTop: "3px solid #ffc107",
            width: "100px",
            margin: "20px auto",
            opacity: "1",
          }}
        />

        <div className="container">
          <div className="container mt-3" id="best-website-designer-ahmedabad">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-lg-6 col-md-12 mb-4">
                <img src={web3} alt="Website Designing" className="img-fluid" />
              </div>
              <div className="col-lg-6 col-md-12">
                <p className="top-para">
                  Are you searching for “Best Website Designing Services in
                  Ahmedabad” or “Web Development Company in Ahmedabad”? Bytefaze
                  is the perfect solution. We are proficient in making creative
                  websites or web pages. As a Gujarat's leading<Link to="/"> website design company</Link>, we know
                  great websites need both amazing design and strong development
                  working together. That’s why we use the best and latest teachnology tools and work
                  closely as a team of experienced designers and developers to
                  deliver an exceptional website that is:
                </p>
                <ul className="top-para">
                  <li>
                  <b>Visually Appealing:</b> We’ll create a website that is
                  modern, professional, and reflects your brand identity.
                  </li>
                  <li className="mt-2">
                  <b>User-Friendly:</b> Your website will be easy to navigate
                    and find information, ensuring a positive user experience
                    for all visitors.
                  </li>
                  <li className="mt-2">
                  <b>Responsive:</b> We will make your website look great and
                    function flawlessly on all devices, from desktops to tablets
                    and smartphones.
                  </li>
                  <li className="mt-2">
                  <b>Search Engine Optimized (SEO):</b> We build our websites with SEO best practices in mind, helping you rank higher in search engine results pages and attract more organic traffic.
                  </li>
                  <li className="mt-2">
                  <b>Conversion-Focused:</b> We’ll design your website layout with
                    clear call to action, encouraging visitors to take the next
                    step, whether it’s contacting you, making a purchase, or
                    signing up for your newsletter.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <section>
            <div style={{ background: "var(--footer-bg)" }} className="row mt-5" >
              <div className="container row  text-center pt-5 pb-5">
                <h2 className="top-para text-center mb-3">Ready to Transform Your Online Presence ? Let's Build Your Dream Website Together</h2>
                <div className="d-flex justify-content-center">
                  <Link to="/contact-us">
                    <button className="button-33 mb-2 col-12 col-lg-12 mt-3">Get A Free Quote Now! </button>
                  </Link>
                </div>

              </div>
            </div>
          </section>
          <div className="container mt-3 mb-5">
            <h2 className="text-center mt-4 top-para">
            Quick Website Designing Service Finder
            </h2>
            <hr
              style={{
                borderTop: "3px solid #ffc107",
                width: "100px",
                margin: "20px auto",
                opacity: "1",
              }}
            />

            <div className="row">
              {[
                {
                  href: "#best-website-designer-ahmedabad",
                  text: "Best Website Designer Ahmedabad",
                },
                {
                  href: "#different-types-of-web-design",
                  text: "Different Types of Website Design Ahmedabad",
                },
                {
                  href: "#web-design-services",
                  text: "What are Web Design Services?",
                },
                {
                  href: "#why-good-web-design-matters",
                  text: "Benefits of Having a Good Web Design",
                },
                {
                  href: "#key-elements-of-website-designing",
                  text: "Key Elements of Website Designing",
                },
                {
                  href: "#responsive-website-design",
                  text: "Responsive Website Design",
                },
                {
                  href: "#user-experience-website-design",
                  text: "User Experience (UX) Website Design",
                },
                {
                  href: "#customized-website-designer",
                  text: "Customized Website Services",
                },
                {
                  href: "#website-redesign-services-in-ahmedabad",
                  text: "Website Redesign Services in Ahmedabad",
                },
                {
                  href: "#website-maintenance-and-support-service",
                  text: "Website Maintenance & Support Services",
                },
                { href: "#faq", text: "FAQ's" },
              ].map((item, index) => (
                <div
                  className="col-lg-4 col-md-6 col-12 mb-2 d-flex align-items-center justify-content-center text-center"
                  key={index}
                >
                  <a
                    href={item.href}
                    className="card-link mb-0 p-2 border-dotted rounded-card hover-effect w-100 d-flex align-items-center justify-content-center"
                  >
                      {item.text}
                   
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div
            className="row justify-content-center text-center"
            id="different-types-of-web-design"
          >
            <div className="col-12">
              <h2 className="text-center mt-4 top-para">
              Different Types of Website Design in Ahmedabad
              </h2>
              <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "100px",
                  margin: "20px auto",
                  opacity: "1",
                }}
              />

              <p className="top-para">
              We are counted among the top 10 Website Designing agencies in
                Ahmedabad which cater to customers' web design needs with a
                variety of services. From designing extremely beautiful and
                manageable websites that reflect your brand’s identity to
                building seamless e-commerce platforms, Bytefaze excels in
                creating digital solutions tailored to your business needs.
                Contact us today for professional website development in
                Ahmedabad. We have expertise in designing various{" "}
                <b>types of website design in Ahmedabad</b> that fulfill the
                requirements of clients listed below:
              </p>
            </div>
            <div className="col-12">
              <div className="row mt-4">
                {designTypes.map((type, index) => (
                  <div key={index} className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="card d-flex flex-row align-items-center p-3">
                      <div className="me-3">{type.icon}</div>
                      <div>
                        {type.link ? (
                          <Link to={type.link}>{type.title}</Link>
                        ) : (
                          type.title
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="container py-5" id="web-design-services">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <h2 className="text-center mt-4 top-para">
                What are Web Design Services?
                </h2>
                <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "100px",
                    margin: "20px auto",
                    opacity: "1",
                  }}
                />

                <p className="lead mb-4">
                Our <strong>Web design services in Ahmedabad</strong>{" "}
                  encompass a variety of tasks aimed at creating a visually
                  appealing, user-friendly, and functional website. Here’s a
                  breakdown of some key aspects:
                </p>
                <ul className="text-start mb-4 top-para">
                  <li>Content Management System (CMS) Integration</li>
                  <li>User Experience (UX) Design</li>
                  <li>User Interface (UI) Design</li>
                  <li>Responsive Design</li>
                  <li>Integration with Ecommerce Platforms</li>
                </ul>
                <p className="lead">
                Bytefaze is one of those{" "}
                  <strong>top web design service providers in India</strong>{" "}
                  whose services are always tailored to the key aspects of the
                  customer.
                </p>
                <div className="d-flex justify-content-center justify-content-lg-start mt-4">
                  <Link to="/blog/what-is-website-design">
                    <button className="button-33 btn-lg">
                      Also Read: What is Web Design?
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-4 mt-lg-0 text-center">
                <img
                  src={web4}
                  alt="Web Design Services in Gujarat"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <section className="py-3 py-md-5 py-xl-8">
          <div className="container flex" id="why-good-web-design-matters">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <img
                  src={webdesign}
                  alt="Web Design Agency Ahmedabad, Gujarat, India"
                  className="img-fluid mt-1"
                  width={600}
                />
              </div>
              <div className="col-lg-6 mt-2">
                <div>
                  <h2 className="top-heading">
                  Benefits of Having a Good Web Design
                  </h2>
                  <hr
                    style={{
                      borderTop: "3px solid #ffc107",
                      width: "100px",
                      margin: "20px auto",
                      opacity: "1",
                    }}
                  />
                  <p className="top-para">
                  A well-designed website is considered crucial for business
                    success. Firstly, it serves as your digital storefront,
                    making the first impression on potential clients.
                    Furthermore, good web design can:
                  </p>
                  <ul className="top-para">
                    <li>
                      <b>Build Credibility</b>
                    </li>

                    <li>
                      <b>Enhance User Experience</b>
                    </li>

                    <li>
                      <b>Boost SEO</b>
                    </li>

                    <li>
                      <b>Increase Conversion Rates</b>
                    </li>
                  </ul>
                  <p className="top-para">
                  Bytefaze, a <b>best website design company in Ahmedabad</b>,
                    offers comprehensive website development services that
                    prioritize the tenets of Good Web Design Matters. First, we carefully consider user experience. 
                    Websites are  crafted to be easy to navigate and information is presented
                    in a readily accessible manner. Next, search engine
                    optimization and mobile responsiveness are addressed. This
                    ensures websites reach their target audience effectively. 
                  </p>
                  <p className="top-para">In addition to functionality, visually appealing designs are
                    created. Furthermore, websites are crafted to be not only
                    aesthetically pleasing but also functionally sound. Finally,
                    with Bytefaze’s web design services, websites are
                    transformed into effective tools that convert visitors into
                    customers.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="key-elements-of-website-designing">
            <div className="container mt-5 mb-5">
              <div className="col-12">
                <h2 className="text-center mt-4 top-para">
                  Key Elements of Website Designing
                </h2>
                <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "100px",
                    margin: "20px auto",
                    opacity: "1",
                  }}
                />

                <p className="top-para">
                  Effective website elements increase visual appeal while also
                  improving overall SEO and CRO efforts, hence aiding your
                  marketing and revenue channels. The following are the prime
                  key Elements of latest web design that will improve your
                  website's visual and functional appeal in 2024:
                </p>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 order-2 order-lg-1 mt-2">
                  <div>
                    <ul className="top-para">
                      <li>
                        <b>Visual Components</b>
                        <p className="top-para">
                        Visual aspects work together to create a theme for
                          website, when its done correctly. Also it has a
                          significant impact on visitors. It is critical that
                          all elements complement one other and appear
                          harmonious rather than chaotic. Visual aspects include
                          the following:
                        </p>
                      </li>
                      <li>
                        <b>Function Components</b>
                        <p className="top-para">
                          Functional components are crucial for how a search
                          engine perceives your website. Beyond that, though,
                          they can also have an impact on the user experience.
                          The following are a few prominent functional elements:
                        </p>
                        <ul className="top-para">
                          <li>
                            <b>Navigation :</b> The simplicity with which your
                            website's users can access its many sections
                          </li>
                          <li>
                          <b>User interaction :</b> Giving users control by
                            removing unnecessary pop-ups, automatically playing
                            movies, and excessive information. Speed: Making
                            sure the page loads quickly.
                          </li>
                          <li>
                          <b>Site structure :</b> A clean, orderly layout makes it easy for users to navigate and for Google's web crawlers to follow. Additionally, ensure interoperability across devices.


                          </li>
                        </ul>
                      </li>
                      <li><b>Quality Content</b>
                      <p className="top-para">Quality content is a crucial element of effective website design, as it enhances user experience and drives engagement. Well-crafted content not only communicates the value of your services but also guides users through their journey, making navigation intuitive and enjoyable.</p><p className="top-para"> By including clear headlines, concise messaging, and strong calls-to-action, you can ensure visitors find what they need quickly. Moreover, high-quality, keyword-optimized content improves your site’s search engine ranking, attracting organic traffic and increasing visibility. In essence, prioritizing quality content in your website design creates a compelling user experience that fosters trust and encourages conversions.</p></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                  <img
                    src={keyElement}
                    alt="Website Design & Development in Ahmedabad"
                    className="img-fluid mt-1"
                    width={500}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="responsive-website-design">
            <div className="row align-items-center justify-content-center mt-5">
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <img
                  src={res}
                  alt="Responsive Website Designing Services India"
                  className="img-fluid mt-1"
                  width={600}
                />
              </div>
              <div className="col-lg-6 mt-2">
                <div>
                  <h2 className="top-heading">Responsive Website Design</h2>
                  <hr
                    style={{
                      borderTop: "3px solid #ffc107",
                      width: "100px",
                      margin: "20px auto",
                      opacity: "1",
                    }}
                  />
                  <p className="top-para">
                  In today’s mobile-first world, a responsive website is
                    essential. Therefore, responsive web design services are
                    provided to ensure that your site looks and functions
                    perfectly on all devices, from desktops to smartphones. The
                    benefits include:
                  </p>
                  <ul className="top-para">
                    <li>
                    <b>Improved User Experience</b> A consistent look and feel
                    are provided across all devices.
                    </li>
                    <li>
                    <b>Better SEO Performance</b> mobile friendly websites are
                    prioritized by Google in search results.
                    </li>
                    <li>
                    <b>Increased Engagement</b> Users are more likely to stay
                    longer and interact with your site
                    </li>
                  </ul>
                  <p className="top-para">
                  At ByteFaze, we provide{" "}
                  <b>
                      <Link to="/responsive-website">
                        Responsive website designing
                      </Link>
                    </b>{" "}
                    (RWD) services to ensure your website adapts seamlessly to
                    different screen sizes and devices. Whether your users
                    access your site on a desktop, tablet, or smartphone, our
                    RWD services guarantee a consistent and user-friendly
                    experience. Responsive web design is not just a trend; it’s
                    a necessity for modern websites.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="user-experience-website-design">
            <div className="container mt-5 mb-5">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 order-2 order-lg-1 mt-2">
                  <div>
                    <h2 className="top-heading">
                    User Experience (UX) Website Design
                    </h2>
                    <hr
                      style={{
                        borderTop: "3px solid #ffc107",
                        width: "100px",
                        margin: "20px auto",
                        opacity: "1",
                      }}
                    />
                    <p className="top-para">
                    Our company offers specialized <b>User Experience (UX)
                        Website Design services in Ahmedabad</b>, ensuring your
                      website provides a superior and intuitive user journey. By
                      focusing on user needs and behaviors, we create websites
                      that are not only visually appealing but also highly
                      functional and easy to navigate.
                    </p>
                    <p className="top-para">
                      <b>Benefits of Our UX Design Services</b>
                    </p>
                    <ul className="top-para">
                      <li>
                        <b>Enhanced Usability </b>
                      </li>
                      <li>
                        <b>Increased Engagement </b>
                      </li>
                      <li>
                        <b>Higher Conversion Rates </b>
                      </li>
                      <li>
                        <b>Inclusive Accessibility</b>
                      </li>
                      <li>
                        <b>Improved Customer Satisfaction</b>
                      </li>
                    </ul>
                    <p className="top-para">
                    As a <b>top web designing company</b>, we offer expert
                      UI/UX design services. Our skilled team ensures visually
                      appealing websites with intuitive user experiences.
                      Through thorough research, wireframing, and testing, we
                      deliver the best errore free web design. We prioritize mobile
                      optimization and information architecture for seamless
                      functionality across all devices. Choose us to elevate
                      your digital presence and engage your audience
                      effectively.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                  <img
                    src={uiuxImg}
                    alt="Best Web Design Company in India"
                    className="img-fluid mt-1"
                    width={600}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-center mt-5">
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <img
                  src={builder}
                  alt="Top Website Designing In Ahmedabad"
                  className="img-fluid mt-1"
                  width={600}
                />
              </div>
              <div className="col-lg-6 mt-2">
                <div>
                  <h3 className="top-heading" id="customized-website-designer">
                  Custom Website Designer in Ahmedabad
                  </h3>
                  <hr
                    style={{
                      borderTop: "3px solid #ffc107",
                      width: "100px",
                      margin: "20px auto",
                      opacity: "1",
                    }}
                  />
                  <p className="top-para">
                  When considering your options for website development,
                    you’ll likely come across website builders and custom web
                    design developers. Website builders provide pre-designed templates and easy drag-and-drop interfaces. This makes them perfect for creating basic websites with limited customization options. 
                  </p>
                  <p className="top-para">On the
                    other hand, custom web design developers, like ByteFaze,
                    provide tailored solutions crafted to meet your specific
                    needs. With custom development, you have complete control
                    over the design, functionality, and usability of your
                    website.</p>

                  <p className="top-para">
                  As a leading
                    <b>
                      {" "}
                      web designing & development company in Ahmedabad,
                    </b>{" "}
                    ByteFaze offers top-notch website design services tailored
                    to your requirements. Our team of expert web developers
                    specializes in developing custom websites that stand out in
                    today’s competitive digital landscape. Whether you need a
                    simple brochure website or a complex e-commerce platform,
                    our custom web design services are designed to elevate your online
                    presence and drive results.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container mt-5">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 order-2 order-lg-1 mt-2">
                  <div>
                    <h3
                      className="top-heading"
                      id="website-redesign-services-in-ahmedabad"
                    >
                      Website Redesign Services in Ahmedabad
                    </h3>{" "}
                    <hr
                      style={{
                        borderTop: "3px solid #ffc107",
                        width: "100px",
                        margin: "20px auto",
                        opacity: "1",
                      }}
                    />
                    <p className="top-para">
                    <b>Website redesign services </b>are offered to revamp
                      outdated websites, making them modern and functional. The
                      redesign process at Bytefaze begins with a deeply analysis
                      of the existing site to identify areas for improvement.
                      Subsequently, a new design is developed that incorporates
                      the latest design trends and technologies. Moreover, the
                      site's performance and user experience are significantly
                      enhanced, thereby improving overall effectiveness.
                    </p>
                    <h3
                      className="top-heading"
                      id="website-maintenance-and-support-service"
                    >
                      Website Maintenance and Support Services
                    </h3>
                    <hr
                      style={{
                        borderTop: "3px solid #ffc107",
                        width: "100px",
                        margin: "20px auto",
                        opacity: "1",
                      }}
                    />
                    <p className="top-para">
                    We offers <b>Website maintenance and support services </b>{" "}
                      that ensure websites remain up-to-date, secure, and fully
                      functional. Regular updates, security checks, and
                      performance optimizations are carried out to prevent any
                      issues. Furthermore, our technical support is readily
                      available to address any problems that may arise, ensuring
                      continuous smooth operation.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                  <img
                    src={webmaintain}
                    alt="Website Redesign Services"
                    className="img-fluid mt-1"
                    width={600}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <Testimonials />
        </div>
        <div id="faq">
          <Websitefaq />
        </div>
      </div>
    </Layout>
  );
};

export default WebsiteDesign;
