import React, { useEffect } from "react";
import Layout from "../../layout/Layouts";
import img1 from "../../../assets/WebpImages/web-designing-ahmedabad.webp";
import { Link, useLocation } from "react-router-dom";
import Card from "../Card";
import logo from "../../../assets/WebpImages/bytedark.webp";
import Accordian from "../../homecomponents/Accordian.jsx";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const Websitedesignblog = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); // Remove the '#' to get the id
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const data = {
    title: "Web Design in Ahmedabad | Designing Services | Top Company",
    description:
      "Boost your online presence with Ahmedabad’s Top Website Design Agency. Bytefaze offers best website designing services competative to Responsive, UI/UX graphics.",
    keywords:
      "Web Design Ahmedabad, Website Designing Company, Website Design Services, Website designing agency, Bytefaze Web Solutions Ahmedabad",
    img: img1,
    altTag: "Web Design in Ahmedabad", //image Alt tag
    tag: "Web Design with Bytefaze",
    label: "Web Design in Ahmedabad",
    PageTitle: "Embracing the Future of Web Design with Bytefaze Web Solution",
    date: "11 Apr 2024",
    desc: (
      <p className="lead fw-bold">
        In the heart of Gujarat, Ahmedabad is not just known for its rich
        cultural heritage but also for its burgeoning tech scene. Among the many
        facets of this tech expansion is the rise of top web design companies
        that are transforming the digital landscape.
      </p>
    ),
    img2: img1,
    points: (
      <>
        <div className="container w-75">
          <div className="row  item-align-center col-md-12 text-center">
            <table className="table border">
              <thead>
                <tr>
                  <th style={{ color: "var(--link-color)" }}>
                    Table of Contents
                  </th>
                </tr>
              </thead>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#why-good-website-design-matters">
                    Why a Good Website Design Matters?
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#approch-to-web-designing-branding">
                    Our Approach to Web Designing & Branding
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#creative-web-design-company-ahmedabad">
                    Creative Web Design Company in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#top-web-design-company-ahmedabad">
                    Top Web Design Company Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#web-designing-ahmedabad">
                    Web Designing in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#web-design-for-startups">
                    Web Design for Startups in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#faq">FAQ's</a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        {/* point1 */}
        <h2 style={{ color: "var(--link-color)" }} className="pt-2">
          Top Web Design Services in Ahmedabad with Bytefaze
        </h2>
        <p className="top-para">
          Bytefaze stands at the forefront of this revolution. As
          an{" "}
          <Link style={{ color: "blue" }} to="/">
            <b>best website designing company in Ahmedabad</b>
          </Link>
          , we specialize in creating websites that are not just visually
          appealing but also highly functional, seo freindly and user-friendly.
        </p>
        {/* point1 */}
        {/* point2 */}
        <h2
          style={{ color: "var(--link-color)" }}
          className="pt-2"
          id="why-good-website-design-matters"
        >
          Why a Good Website Design Matters?
        </h2>
        <p className="top-para">
          In today’s digital age, a website is often the first point of contact
          between a business and its potential customers. It’s where first
          impressions are formed and where business decisions are influenced.
          That’s why at Bytefaze, we believe in <b>designing websites</b> that
          truly represent your brand’s ethos and engage your audience
          effectively. Know more Benefits of{" "}
          <a href="/website-designing/#why-good-web-design-matters">
            Good Web design
          </a>{" "}
          through our service page.
        </p>
        {/* point2*/}
        {/* point3 */}
        <h2
          style={{ color: "var(--link-color)" }}
          className="pt-2"
          id="approch-to-web-designing-branding"
        >
          Our Approach to Web Design & Branding
        </h2>
        <p className="top-para">
          Our approach is simple yet impactful. We start by understanding your
          business goals and target audience. This allows us to create a web
          design that’s not just beautiful but also aligned with your business
          objectives. We focus on:{" "}
        </p>
        <ul style={{ color: "var(--link-color)" }}>
          <li>
            <b>Responsive Design:</b> Ensuring your website looks great and
            functions seamlessly on all devices.
          </li>
          <li>
            <b>User Experience (UX):</b> Crafting intuitive navigation and
            engaging content that keeps users on your site longer.
          </li>
          <li>
            <b>Search Engine Optimization (SEO):</b> Implementing strategies to
            improve your site’s visibility and ranking on search engines.
          </li>
        </ul>

        {/* point3*/}
        <h2
          style={{ color: "var(--link-color)" }}
          className="pt-2"
          id="creative-web-design-company-ahmedabad"
        >
          Creative Web Design Company in Ahmedabad
        </h2>
        <p className="top-para">
          Ahmedabad boasts a vibrant design scene, and Bytefaze stands out as a{" "}
          <b>
            <Link to="/website-designing/">creative web design company</Link>
          </b>{" "}
          that injects exceptional creativity into every project. Our team is a
          passionate collective, brimming with fresh ideas and fueled by a
          dedication to transforming your vision into a digital masterpiece.
          However, our company is recognizes that aesthetics are just one piece
          of the puzzle. We understand the crucial link between user experience
          and achieving your business goals. That's why we create websites that
          not only captivate visitors with stunning design, but also
          strategically guide them towards taking the desired action.{" "}
        </p>
        <p className="top-para">
          Whether your aim is to generate leads, boost sales, or solidify brand
          awareness, our user-centric approach ensures your website converts
          interest into results. Furthermore, in today's mobile-first world,
          Bytefaze prioritizes <b>responsive web design</b>. This ensures your
          website seamlessly adapts across desktops, tablets, and smartphones,
          guaranteeing a flawless user experience regardless of the device. We
          don't stop at launch, though.{" "}
        </p>
        <p className="top-para">
          Bytefaze is committed to providing ongoing support and maintenance,
          ensuring your website remains secure, up-to-date, and continues to
          deliver optimal performance. Finally, we believe transparency is key.
          That's why we provide insightful data and analytics to track your
          website's success and continuously refine your online strategy for
          measurable results. So, if you're searching for a{" "}
          <b>
            <Link to="/blog/best-web-design-services">
              best web design services in Ahmedabad
            </Link>
          </b>{" "}
          who prioritizes both creativity and measurable impact, look no further
          than Bytefaze.
        </p>
        {/* point4 */}
        <h2
          style={{ color: "var(--link-color)" }}
          className="pt-2"
          id="top-web-design-company-ahmedabad"
        >
          Top Web Design Company Ahmedabad
        </h2>
        <p className="top-para">
          What sets Bytefaze apart is our commitment to excellence and
          innovation. We are constantly exploring new{" "}
          <Link to="/blog/riding-the-wave-unveiling-the-latest-trends-in-web-design">
            <b>web design trends</b>
          </Link>{" "}
          and technologies to provide our clients with cutting-edge solutions.
          Our team of designers, developers, and digital marketers work in
          synergy to deliver a comprehensive web solution that drives results.
        </p>

        {/* point5 */}
        <h2
          style={{ color: "var(--link-color)" }}
          className="pt-2"
          id="web-designing-ahmedabad"
        >
          Web Designing in Ahmedabad
        </h2>
        <p className="top-para">
          As Ahmedabad continues to grow as a tech hub, We are here to ensure
          your business doesn’t just keep up but stands out and grow fast.
          Whether you’re looking to create a new website or redesign an existing
          one, We provide services to guide you through every step of the
          digital journey.
        </p>
        {/* point6*/}
        <h2 style={{ color: "var(--link-color)" }} id="web-design-for-startups">
          Web Design for Startups in Ahmedabad
        </h2>
        <p className="top-para flex">
          In the exciting world of Ahmedabad startups, Bytefaze stands out as a
          leading web design services that understands the importance of a
          strategic online presence. We specialize in{" "}
          <b>"web design for startups</b>", making cost-effective websites that
          perfectly capture your brand's essence and resonate with your target
          audience. Our lean and agile approach ensures a smooth and efficient
          process, ideal for startups navigating the initial growth phase. We
          prioritize user-friendly interfaces that convert visitors into paying
          customers, helping you establish a strong foundation for online
          success. Additionally, Bytefaze leverages its SEO expertise to boost
          your website's search engine ranking, ensuring your Ahmedabad startup
          is discoverable by potential customers searching for the products or
          services you offer. Let Bytefaze be your web design company and make a
          website that fuels the launch and growth of your Ahmedabad startup.
        </p>
        <div id="faq">
          <Accordian />
        </div>
      </>
    ),
    lastp:
      "This blog post is designed to engage readers and potential clients by highlighting the importance of web design Ahmedabad and the unique services offerings by Bytefaze. Contact us and Enquire us for more detalis like web design cost, website designing prices, website design templates, Website development time etc.",
    Fieldtags: (
      <>
        <div className="container mt-1">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-wrap">
                <Link to="/" className="btn btn-outline-secondary m-1">
                  Website Designing Services Ahmedabad
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Web Design Company
                </Link>
                <Link
                  to="/responsive-website/"
                  className="btn btn-outline-secondary m-1"
                >
                  Website Designing Agency
                </Link>
                <Link
                  to="/digital-marketing/"
                  className="btn btn-outline-secondary m-1"
                >
                  Best Website Designing Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Top Website Designing Services
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  #1 Website Design Company
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Web Design Ahmedabad
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Web Designing Ahmedabad
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Web Designing Ahmedabad
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    ),

    // "In today's digital world, having a strong online presence is essential for any local business. A well-designed website acts as your digital storefront, attracting new customers and keeping existing ones engaged. Here's a breakdown of website design services specifically tailored for local businesses:"
  };

  return (
    <Layout
      title={data?.title}
      keywords={data?.keywords}
      description={data?.description}
      canonical={"https://www.bytefaze.com/website-designing"}
      url={
        "https://www.bytefaze.com/blog/embracing-the-future-of-web-design-in-ahmedabad-with-bytefaze-web-solution"
      }
      ldName={"Future of Web Design in Ahmedabad With Bytefaze"}
      ldImage={img1}
    >
      {/* <!-- Featured 1 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <div
            className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between"
            style={{ color: "var(--link-color)" }}
          >
            <div className="col-12 col-lg-7 order-1 order-lg-0">
              <div className="entry-header mb-3">
                <ul className="entry-meta list-unstyled d-flex mb-3">
                  <li>
                    <a
                      className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                      href="#"
                    >
                      {data?.label}
                    </a>
                  </li>
                </ul>
                <h1 className="display-3 fw-bold mb-4">{data?.PageTitle}</h1>
              </div>
              <div className="entry-footer">
                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                  <li>
                    <a
                      className="fs-7  text-decoration-none d-flex align-items-center"
                      href="#"
                    >
                      <svg
                        xmlns="https://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fillRule="currentColor"
                        className="bi bi-calendar3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                      <span
                        className="ms-2 fs-7"
                        style={{ color: "var(--link-color)" }}
                      >
                        {data?.date}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <a
                className="d-block bsb-hover-image overflow-hidden rounded"
                href="#"
              >
                <img
                  className="img-fluid bsb-scale bsb-hover-scale-up"
                  src={img1}
                  alt="Ahmedabad's Top Web Design Services Provider Company in India"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Entry Content Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10">
          <div className="container">
            <div className="row justify-content-md-center gy-3 gy-xl-4">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.desc}
                {/* <p className="lead fw-bold">{data?.desc} <a href="#">emotional support</a> in a way that is healthy for both you and your partner.</p> */}
              </div>

              <div className="col-12 text-center">
                <img
                  className="img-fluid my-4 rounded"
                  src={img1}
                  alt="Website Designing Company Ahmedabad"
                />
              </div>

              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.points}
                <p>{data?.points2}</p>
                {/* <h2 style={{color:"var(--link-color)"}}>Offer Physical Affection</h2> */}
                {/* <img  className="img-fluid mt-3 mb-5 rounded" src={img1} alt=""/> */}
                <p>{data?.lastp}</p>
                {/* <p>Different people like different levels of physical affection at different times. If you are feeling unsure, communicate with your partner so you can learn what they are open to receiving and can provide it accordingly.</p> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Entry Meta --> */}
        <div className="pb-3 pb-md-5 pb-xl-10">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <span
                  className="me-1 fw-bold"
                  style={{ color: "var(--link-color)" }}
                >
                  {" "}
                  Tags:
                </span>
                <ul className="entry-meta list-unstyled d-inline-flex align-items-center justify-content-center mb-3">
                  {data.Fieldtags}
                </ul>
              </div>
            </div>
          </div>
        </div>


        {/* <!-- Author 2 - Bootstrap Brain Component --> */}
        <section className="bsb-author-2 py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1X">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">
                <div className=" border-light-subtle p-4 border">
                  <div className="row gy-3 align-items-center">
                    <div className="col-md-4">
                      <a
                        href="#"
                        className="bsb-hover-image d-block rounded overflow-hidden"
                      >
                        <img
                          className="img-fluid author-avatar bsb-scale bsb-hover-scale-up"
                          src={logo}
                          alt="Web Designing Ahmedabad"
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h6
                          className="card-subtitle mb-2  "
                          style={{ color: "var(--link-color)" }}
                        >
                          Article written by
                        </h6>
                        <h3 className="card-title mb-2">
                          <a
                            className="card-link  text-decoration-none"
                            href="#"
                          >
                            Bytefaze
                          </a>
                        </h3>
                        <p className="card-text mb-3">
                          We are skilled in all aspects of web design, from
                          planning and development to design and testing. I also
                          have experience in web development technologies.
                        </p>
                        <ul className="bsb-social-media nav m-0">
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
                            >
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-facebook"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                  <a className="nav-link " href="#">
                    <svg xmlns="https://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li> */}
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://x.com/Bytefaze"
                            >
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-twitter"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              href="https://www.linkedin.com/company/bytefaze/"
                            >
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1X ">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12">
                <h2
                  className="h4 mb-4 mb-md-5"
                  style={{ color: "var(--link-color)" }}
                >
                  Related Articles
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="container overflow-hidden px-4"> */}
          {/* <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}
          <Card />
          {/* </div> */}
          {/* </div> */}
        </section>

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2
                  className="display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis"
                  style={{ color: "var(--link-color)" }}
                >
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Websitedesignblog;
