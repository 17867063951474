import React from "react";
import Layout from "../layout/Layouts";
// import "../../Styles/Services.css";
import dig1 from "../../assets/WebpImages/webdesign.webp";
import lv from "../../assets/WebpImages/it-training-certificate.webp"
import { Link } from "react-router-dom";
import Testimonials from "../pages/Testimonials.jsx";

const Ittraining = () => {
  return (
    <Layout title={"Top IT Training in Ahmedabad | Best Professional IT Courses - Bytefaze"} 
    keywords={"IT Services, Software Development, Mobile Application Development, Web App Development, Best IT Solutions"} 
    description={"Looking for IT Training in Ahmedabad ? Bytefaze is a leading IT company offering affordable and professional IT Courses for college students. Join The Best IT Training Program Now !"}
    canonical={"https://www.bytefaze.com/it-training-in-ahmedabad"}
    ldName={"IT Training"}
    ldImage={dig1}
    serviceType={"IT Training"}
    >
    
    <div style={{height:"250px"}} className="col-12  webdesign_bg">
        <h1 className="display-4 fw-bold">IT Training</h1>
        <p className="text-center col-6">" Develop The Tech-Skills To Thrive In Today's Fast-Paced Digital World With Our IT Training  "
</p>
<div className='d-flex justify-content-center '>
             <Link to="/contact-us">
              <button className="button-33 mb-2 col-12 col-lg-12 mt-3">Get Started With Us</button>
                </Link>
                </div>
      </div>
<section>
    <div className="container">
        <div className="row">
        <h2 className="mt-3" style={{color:"var(--link-color)"}}>Best IT Training Company in Ahmedabad</h2>
        <hr className="mt-2" style={{ borderTop: "3px solid #ffc107", width: "10%", margin: "20px ", opacity: "1", }} />
        <p className="top-para">Are you looking to ignite your IT career with cutting-edge skills? Join us at Bytefaze is an Ahmedabad's premier <b>IT training company</b>, where we empower individuals to become tech-savvy professionals. We offers top-notch IT training programs designed specifically for students like you. Our courses are tailored to equip you with the skills and knowledge needed to excel in today’s competitive job market.</p>
        </div>
        <div className="row">
        <h3 className="text-center top-para">Our Best IT Courses</h3>
                    <hr className="mt-2" style={{ borderTop: "3px solid #ffc107", width: "10%", margin: "20px auto", opacity: "1", }} />
                    <p className="top-para text-center">At Bytefaze, <b>we provide a wide range of IT courses </b>that cater to different interests and career goals</p>
        </div>
        <div className="row">
<div className="col-lg-3">
    <h4 className="text-center">Basic Courses</h4>
    <hr className="mt-2" style={{ borderTop: "3px solid #ffc107", width: "10%", margin: "20px auto", opacity: "1", }} />
    <ul>
        <li className="mb-2">HTML 5</li>
        <li className="mb-2">CSS3</li>
        <li className="mb-2">Basic Javascript</li>
        
    </ul>
</div>
<div className="col-lg-3">
    <h4 className="text-center">Web Designing Courses</h4>
    <hr className="mt-2" style={{ borderTop: "3px solid #ffc107", width: "10%", margin: "20px auto", opacity: "1", }} />
    <ul>
        <li className="mb-2">Website Development</li>
        <li className="mb-2">UI/UX Design</li>
        <li className="mb-2">CSS Training Course</li>
        
    </ul>
</div>
<div className="col-lg-3">
    <h4 className="text-center">Software Development Courses</h4>
    <hr className="mt-2" style={{ borderTop: "3px solid #ffc107", width: "10%", margin: "20px auto", opacity: "1", }} />
    <ul>
        <li className="mb-2">React JS</li>
        <li className="mb-2">Node JS</li>
        <li className="mb-2">Angular JS</li>
        <li className="mb-2">Python</li>
        <li className="mb-2">PHP</li>
        <li className="mb-2">Laravel </li>
        <li className="mb-2">Django</li>
        <li className="mb-2">Java </li>
    </ul>
</div>
<div className="col-lg-3">
    <h4 className="text-center">Live Project Training </h4>
    <hr className="mt-2" style={{ borderTop: "3px solid #ffc107", width: "10%", margin: "20px auto", opacity: "1", }} />
    <ul>
        <li className="mb-2">Live Project in React JS</li>
        <li className="mb-2">Live Project Training in Node Js</li>
        <li className="mb-2">Live Project Training in Django</li>
        <li className="mb-2">Live Project Training in Python</li>
    </ul>
</div>


                </div>
             <div className="row text-center">
             <h4>Key Highlights of the Program</h4>
             <hr className="mt-2" style={{ borderTop: "3px solid #ffc107", width: "10%", margin: "20px auto", opacity: "1", }} />
             </div>
             <div className="row">   
                <div className="col-lg-7">
                <ol>
                <li className='top-para mb-2'><b>Expert-Led Training:</b>
                    <ul>
                        <li className='top-para mb-2 mt-2'>Our trainers are seasoned <b>industry professionals with extensive experience</b></li>
                        <li className='top-para mb-2'>We provide <b>practical insights</b> and real-world applications of the technologies, ensuring you gain valuable knowledge.</li>
                    </ul>
                </li >
                <li className='top-para mb-2'><b>Hands-On Learning:</b>
                <ul>
                        <li className='top-para mb-2 mt-2'>At Bytefaze, we believe in <b>learning by doing.</b></li>
                        <li className='top-para mb-2'>You’ll <b>work on real-world projects</b> that give you the practical experience needed to succeed in the IT field.</li>
                    </ul>
                </li>
                <li><b>Flexible Schedules:</b>
                <ul>
                        <li className='top-para mb-2 mt-2'>We understand that students have busy schedules, so we <b>offer both online and in-person classes.</b></li>
                        <li className='top-para mb-2'>Choose the learning mode that best fits your lifestyle.</li>
                    </ul>
                </li>
                <li><b>Career Guidance:</b>
                <ul>
                        <li className='top-para mb-2 mt-2'>We don’t just stop at training; <b>we help you launch your career</b>. Give 100% job assistance after completion.</li>
                        <li className='top-para mb-2'>Our placement assistance services include resume building, interview preparation, and job placement support.</li>
                    </ul>
                </li>
            </ol>
            <p className="top-para">Upon completing your IT course training at Bytefaze, you’ll receive a <b>IT training certificate</b> recognized by top IT companies in India. This certificate not only validates your skills but also enhances your job prospects, giving you a competitive edge in the job market.</p>

                    </div>   
                    <div className="col-lg-5 d-flex align-items-center justify-content-center">                            
                       
            <img 
              src={lv}
              alt="It Training With Certificate"
              className="img-fluid"
              
            />       
             
                    </div>        
                   </div>   
                </div>
                <div className="mt-5">
          <Testimonials />
        </div>
</section>
      
    </Layout>
  );
};

export default Ittraining;