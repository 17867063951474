import React, { useState, useEffect } from "react";
import {  useLocation } from "react-router-dom";
// import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
//import "../../Styles/styles.css";

import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase";
import Loader from "../pages/Loader";

const Popup = () => {
  // const form = useRef(null);
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(true); // State to control the visibility of the popup

  useEffect(() => {
    const hiddenPaths = ["/contact-us", "/who-we-are", "/signup","/login"];
    if (hiddenPaths.includes(location.pathname)) {
      setShowPopup(false);
    }
  }, [location]);

  const currentDate = new Date();
  const istDateTime = currentDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    city: '',
    area: '',
    message: '',
    subject: '',
    timestamp: istDateTime,
  });
  const[msg,setMsg] = useState('')


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setMsg(<Loader />);
    e.preventDefault();
    try {
     

      await addDoc(collection(db, 'ContactForms'), formData);
      toast.success('Contact form submitted successfully', { position: 'top-center' });
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        city: '',
        area: '',
        message: '',
        subject: '',       
      });
      setMsg('');
    } catch (error) {
      console.error('Error submitting contact form: ', error);
      toast.error('Failed to submit contact form. Please try again.', { position: 'top-center' });
    }
  };


  return (
    <>
      <div
        className="modal fade show"
        style={{
          display: showPopup ? "block" : "none",
          // backgroundColor: "black",
          zIndex: 99999,
          backgroundColor: "rgba(255, 255, 255, 0.4)",
          WebkitBackdropFilter: "blur(5px)",
          backdropFilter: "blur(5px)",
        }}
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content border">
            <div className="modal-header ">
              <h5 className="modal-title">Contact Us</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowPopup(false)}
              ></button>
            </div>
            <div className="modal-body">
              <form
            
                method="post"
                id="form"
                name="form"
              >
                {/* Your form inputs */}
                <div className="row gy-3 ">
                  <div className="col-12 col-md-6">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-id-card-line"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Enter FirstName"
                className="form-control pop-form-control"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-id-card-line"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Enter LastName"
                className="form-control pop-form-control"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-mail-check-line"></i>
                      </span>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        id="email"
                className="form-control pop-form-control"
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6  ">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-phone-line"></i>
                      </span>
                      <input
                        type="tel"
                        placeholder="Phone Number"
                className="form-control pop-form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        onChange={handleChange}
                        value={formData.phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 py-2">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-user-location-line"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Enter City"
                className="form-control pop-form-control"
                        id="city"
                        name="city"
                        onChange={handleChange}
                        value={formData.city}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 py-2">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-user-location-line"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Area"
                className="form-control pop-form-control"
                        id="area"
                        name="area"
                        onChange={handleChange}
                        value={formData.area}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-chat-thread-line"></i>
                      </span>

                      <input
                        type="text"
                        placeholder="Subject"
                className="form-control pop-form-control"
                        id="subject"
                        name="subject"
                        onChange={handleChange}
                        value={formData.subject}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <textarea
                      placeholder="About your project"
                      onChange={handleChange}
                      value={formData.message}
              className="form-control pop-form-control"
                      id="message"
                      name="message"
                      rows="2"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12  ">
                    <div className="d-grid col-lg-4">
                      <button
                        disabled={msg ? true : false}
                        onClick={handleSubmit}
                        className={`border ${msg ? "btn2" : "btn-pop"} `}
                        type="submit"
                      >
                        INQUIRE NOW
                      </button>
                    </div>
                    <div
                      className="text-center mt-3 col-lg-12 "
                      style={{ color: "black" }}
                    >
                      {msg}
                    </div>
                  </div>
                </div>
                <ToastContainer />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
