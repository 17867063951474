import React from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import Layout from "../layout/Layouts.jsx";
import { EarthCanvas } from "../canvas/index.js";
import Typewriter from "typewriter-effect";
//import "../../Styles/styles.css";
import Accordian from "../homecomponents/Accordian.jsx";
import Why from "../homecomponents/Why.jsx";
import { Link } from "react-router-dom";
import idea from "../../assets/WebpImages/idea.webp";
import DreamProject from "../../assets/WebpImages/dreamproject.webp";
import Responsive from "../../assets/WebpImages/responsive.webp";
import mobile from "../../assets/WebpImages/mobile.webp";
import seo from "../../assets/WebpImages/seo.webp";
import Web from "../../assets/WebpImages/web-design.webp";
import digital from "../../assets/WebpImages/ecom1.webp";
import domain from "../../assets/WebpImages/vr.webp";
import newbg from "../../assets/WebpImages/newbggg.png";
import Card from "../blog/Card.jsx";
import ProjectCounter from "./ProjectCounter.jsx";
import Testimonials from "./Testimonials.jsx";
import WebCalculator from "./WebCalculator.jsx";
import Carasole from "./Carasole.jsx";
import About from "./About.jsx";


import google from "../../assets/WebpImages/Google.webp";
import clutch from "../../assets/WebpImages/clutch.webp";
import designrush from "../../assets/WebpImages/designrush.webp";
import topdevelopers from "../../assets/WebpImages/topdevelopers.webp";
import goodfrims from "../../assets/WebpImages/goodfirms.webp";
import { color } from "framer-motion";
//import { LazyLoadImage } from "react-lazy-load-image-component";
// import Teastloader from "../Teastloader.jsx";

const Hero = () => {
  // const [count,setCount]=useState(0)

  return (
    <>
      <Layout
        className="cool"
        title={
          "Best Web Development & Design Company in India | Professional Website Developer"
        }
        keywords={
          "IT Company Web Development Company, Website Developer, Professional Web Designer, Web Developer, Web Development Services, Website Designing, Custom Website, Latest Design, Fully Optimize, Ahmedabad, Gujarat, India"
        }
        description={
          "Bytefaze is a Professional Website Developer in Ahmedabad, offers website design with UI/UX, Custom AR/VR software development, Responsive ECommerce Website as per client requirement in India. Contact Now !"
        }
        canonical={"https://www.bytefaze.com/"}
        ldName={"Website Development"}
        ldImage={
          "https://www.bytefaze.com/static/media/best-web-designer.76aaa934964c89846462.webp"
        }
        serviceType={"Web Development Services"}
      >
        <div className="container-fluid ">
          <div className="row align-items-center gradient-bg-2 ">
           

            <div className="col-md-6 col-6 fs-2 mb-4  fw-light cool-bbg ">
              <div className="">
                <p className="gradient-bg-2-p"> We Are</p>
              </div>
              <div className="
              cool-heading">
                <h2 className="gradient-bg-2-h2">
                  <Typewriter
                    options={{
                      strings: [
                        "Best Web Developers",
                        "Top Website Designers",
                        "Excellent Coders",
                        "Bytefaze",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h2>
              <p className="text-white gradient-bg-2-p2">Empowering Businesses with Cutting-Edge Web Solutions.</p>

              <Link to={"/contact-us"} className="gradient-bg-button ">Request Pricing</Link>
              </div>
            </div>

            
          </div>
        </div>

        {/* <div>
          <Carasole/>
        </div> */}

<section className="container mt-3 ">
        <div >
          <div className="d-flex align-items-center justify-content-between px-2 scrollable-items gap-5 px-5 ">
            <div className="ratings">
              <Link to='https://clutch.co/profile/bytefaze#highlights' target="_blank">
                <img  className="footer_img" src={clutch} width={70} alt="Clutch" />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-fill"></i>
              </Link>
              &nbsp;&nbsp;5.0
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to="/" target="_blank">
                <img 
                  className="footer_img"
                  src={goodfrims}
                  width={100}
                  alt="Goodfrims"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-fill"></i>
              </Link>
              &nbsp;&nbsp;5.0
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to="/" target="_blank">
                <img 
                  className="footer_img"
                  src={designrush}
                  width={80}
                  alt="Designrush"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-half-line"></i>
              </Link>
              &nbsp;&nbsp;4.5
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to='https://www.topdevelopers.co/profile/bytefaze-web-solutions' target="_blank">
                <img 
                  className="footer_img"
                  src={topdevelopers}
                  width={100}
                  alt="Top Developers"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-half-line"></i>
              </Link>
              &nbsp;&nbsp;4.5
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to='https://maps.app.goo.gl/htfvmBqbdJubqQ69A' target="_blank">
                <img  className="footer_img" src={google} width={80} alt="Google" />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-half-line"></i>
              </Link>
              &nbsp;&nbsp;4.9
            </div>
          </div>
        </div>
   
      </section>

        <header className="py-5 headHero">
          <div className="container px-md-5">
            <section>
              <div className=" text-center w-100">                
                <span className="display-5 pb-2" style={{color:"#0a1745"}}>Bytefaze Web Solutions</span><br/>
                <h1 className="display-4 fw-bolder d-inline main-heading">
                  Best Web Development Company in India
                </h1>
                <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "10%",
                    margin: "20px auto",
                    opacity: "1",
                  }}
                />

                              </div>
            </section>
            <div className="row gx-3 align-items-center">
              <div className="col-md-6">
                <div className="text-center text-xxl-start">
                  {/* <div className="fs-2 mb-4  fw-light secondary-banner randomtext">
                    We Are&nbsp;
                    <span
                      style={{ color: "red", display: "inline-block" }}
                      className="randomtext"
                    >
                      <Typewriter
                        options={{
                          strings: [
                            "Best Web Developers",
                            "Top Website Designers",
                            "Excellent Coders",
                            "Bytefaze",
                          ],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </span>
                  </div> */}
                  <div className="fs-6 mb-4 para-banner">
                  <p className="top-para">
                  By leveraging of 5+ years of professional services experience,
                  bytefaze is counted among one of the rising{" "}
                  <b>Professional Website Development Company in Ahmedabad.</b> We provide cutting-edge
                  website design, UI/UX development, and AR/VR software
                  solutions, helping businesses across India thrive in today’s
                  digital landscape.
                </p>
                    <p>
                      Our team of experts delivers innovative designs that are
                      not only visually appealing but also optimized for user
                      engagement and performance. We stay at the forefront of
                      technology, leveraging the latest trends to craft
                      immersive AR/VR experiences that push the boundaries of
                      interaction and functionality.
                    </p>
                    <p>
                      Whether you're a startup looking for a dynamic online
                      presence or an established business in need of a digital
                      transformation, Bytefaze offers tailored{" "}
                      <b>web development solutions</b> that meet your unique
                      needs. We focus on delivering seamless, user-centered web
                      designs that enhance your brand’s impact and drive
                      business growth. Partner with us to experience a blend of
                      creativity, technical expertise, and a commitment to your
                      online success.
                    </p>
                  </div>
                  <div className=" gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-0  ">
                    
                    <Link className=" me-sm-3 fs-6 fw-bolder" to="/what-we-do">
                      <button className="button-24">Learn More</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-center align-center">
                  <div className="earth_canvas">
                    <EarthCanvas />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5 mt-md-0">
            <ProjectCounter />
          </div>
          {/* <ProjectShowcase/> */}
        </header>

        {/* --------------experimental start-----------------------=========================================-- */}

        <section id="services" className="services ">
          <div className="container">
            <header className="section-header">
              <h2>Discover Our Expertise as professional Web Developer</h2>
              <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "10%",
                  margin: "20px auto",
                  opacity: "1",
                }}
              />
            </header>            
              <p className="top-para mb-4">
                Web development at Bytefaze encompasses a broad spectrum of
                services, from designing websites and web applications to
                implementing advanced UX/UI design, and eCommerce solutions. We
                also specialize in creating robust website design, conducting
                thorough React JS, providing ongoing maintenance, and offering
                consulting services. For businesses seeking a tailored approach,
                we can even develop custom website design to meet specific
                needs. Our expert web developers and award-winning designers
                collaborate to turn ideas into fully realized, cutting-edge
                digital experiences. Here’s what we can create for you
              </p>
            <div className="row gy-4 mb-5 mt-2 mx-5">
              <div className="col-lg-4 col-md-6">
                <div className="service-box ">
                  <i className="icon">
                    <img
                      
                      src={Web}
                      alt="Web Desiging In Ahmedabad"
                      height={50}
                    />
                  </i>

                  <h3>
                    <Link to="/website-designing">Website Designing</Link>
                  </h3>
                  <p>
                    At Bytefaze, a top <b>website design company in India</b>,
                    we create websites that not only look amazing but also
                    deliver results. Our talented design and development team
                    will work closely with you to understand your unique
                    business goals and target audience.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos="zoom-in">
                <div className="service-box">
                  <i className=" icon">
                    <img
                      
                      src={Responsive}
                      alt="Responsive Website Design Services in Ahmedabad, India"
                      height={50}
                    />
                  </i>
                  <h3>
                    <Link to="/responsive-website">Responsive Website</Link>
                  </h3>
                  <p>
                    Creating a <b>responsive website</b> is crucial for success.
                    Customers are our priority. Let's collaborate to ensure our
                    site meets their needs, showcasing our skills and commitment
                    to satisfaction.Ensuring a seamless experience across all
                    devices with responsive design techniques.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos="zoom-out">
                <div className="service-box ">
                  <i className=" icon">
                    <img
                      
                      src={digital}
                      alt="Ecommerce Website Development Comapny in Ahmedabad, India"
                      height={50}
                    />
                  </i>
                  <h3>
                    <Link to="/ecommerce-website">Ecommerce Website</Link>
                  </h3>
                  <p>
                    An <b>E-Commerce website</b> enables online sales with
                    features like product catalogs, shopping carts, and secure
                    payments. Bytefaze develops personalized eCommerce sites
                    with easy navigation, responsive design, and secure
                    transactions.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6" data-aos="fade-up">
                <div className="service-box ">
                  <i className=" icon">
                    <img
                      
                      src={mobile}
                      alt="Best Mobile App Development Company"
                      height={50}
                    />
                  </i>
                  <h3>
                    <Link to="/application-development">
                      Application Development
                    </Link>
                  </h3>
                  <p>
                    Bytefaze is an Ahmedabad-based web and{" "}
                    <b>mobile app development company</b> that specializes in
                    creating high-quality software solutions. Our professional
                    development teams are skilled in a variety of technological
                    stacks, including React JS, PHP, Node JS, Phython, and many
                    more.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos="fade-up">
                <div className="service-box">
                  <i className=" icon">
                    <img
                      
                      src={domain}
                      alt="AR /VR Development Services"
                      height={50}
                    />
                  </i>
                  <h3>
                    {" "}
                    <Link to="/virtual-reality">AR/VR Development</Link>
                  </h3>
                  <p>
                    Bytefaze delivers <b>AR/VR development services</b> that
                    create immersive, custom experiences tailored to your needs.
                    Our cutting-edge solutions enhance user engagement and
                    transform interactions with your brand, perfect for
                    marketing, training, and visualization.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos="fade-up">
                <div className="service-box">
                  <i className=" icon">
                    <img
                      
                      src={seo}
                      alt="SEO Company in Ahmedabad"
                      height={50}
                    />
                  </i>
                  <h3>
                    <Link to="/seo">SEO</Link>
                  </h3>
                  <p>
                    Bytefaze is a one of the <b>best SEO agency in Ahmedabad</b>
                    , Gujarat. We offer 100% organic & result oriented SEO,
                    Social Media Optimization, Link Building & Digital
                    Marketing, PPC services in market at affordable prices. We
                    are experts in optimizing your website by doing in-depth
                    research and analysis of keywords.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* --------------experimental------------------------=====================================================- */}

        {/* --------------------------------------------------------------dreamproject-------------------- */}
        <div className="gradient-bg pt-5 pb-5">
          <div className="container row  text-center pt-4 pb-4">
            <p className="top-para text-center text-white bigfont">
              One Stop Web Development Company To Build Creative Online Presence            </p>
            <p className="text-white">Ready To Make Website That Represents
Your Company And Grows Your Business ? 
            </p>
            <div className="d-flex justify-content-center">
              <Link to="/contact-us">
                <button className="gradient-bg-button mb-2 col-12 col-lg-12 mt-3">
                Get Started Today
                </button>
              </Link>
            </div>
          </div>
        </div>
        <section className="py-3 py-md-5 py-xl-8">
          <div className="container mt-5 ">
            <div className="row align-items-center justify-content-center ">
              <div
                className="col-lg-6 d-flex align-items-center justify-content-center"
                data-aos="fade-up"
              >
                <img
                  src={DreamProject}
                  alt="Website Design In Ahmedabad"
                  className="img-fluid mb-3"
                  width={500}
                  height={500}
                />
              </div>
              <div className="col-lg-6" data-aos="fade-up">
                <div>
                  <h2
                    style={{ fontSize: "10 px" }}
                    className="mb-4 text-center"
                  >
                    Custom Website Designing Services
                  </h2>
                  <hr
                    style={{
                      borderTop: "3px solid #ffc107",
                      width: "10%",
                      margin: "20px auto",
                      opacity: "1",
                    }}
                  />
                  <p className="top-para">
                    At Bytefaze, we create <b>custom websites</b> with a strong
                    focus on UI/UX, ensuring that your site is both visually
                    appealing and easy to use. Our designs are intuitive,
                    allowing visitors to navigate smoothly and enjoy a seamless
                    experience. Every website we build is responsive, meaning it
                    looks and functions perfectly on any device, from desktop to
                    mobile. We prioritize simplicity and functionality, ensuring
                    users can quickly find what they need.
                  </p>
                  <p className="top-para">
                    We take pride in our collaborative approach, working closely
                    with clients to understand their vision and bring it to
                    life. This dedication has made us a trusted web design
                    service provider for hundreds of clients across India,
                    delivering tailored solutions that meet unique business
                    needs.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/website-designing">
                      <button className="button-85 mb-2 col-12 col-lg-12 mt-3">
                        Best Website Design Services
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-3 py-md-5 py-xl-8">
          <div className="container ">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 mt-2">
                <div>
                  <h2 className="top-heading">
                    Trusted Digital Marketing Agency
                  </h2>
                  <hr
                    style={{
                      borderTop: "3px solid #ffc107",
                      width: "10%",
                      margin: "20px auto",
                      opacity: "1",
                    }}
                  />
                  <h5 className="top-para mb-3">
                    {" "}
                    "Boost Your Digital Presence Today With Bytefaze"
                  </h5>
                  <p className="top-para">
                    The main purpose of doing organic digital marketing is to
                    increase visitor engagement, raise brand awareness and
                    generate more enquiry through the websites. Bytefaze offers
                    premium digital marketing services includes{" "}
                    <b>
                      <Link to="/seo">SEO </Link>
                    </b>
                    and Social Media Marketing that are thoughtfully designed to
                    give a strong return on investment and, eventually, improve
                    the brand's reputation. Our team of digital experts utilizes
                    the latest techniques and strategies to ensure your brand's
                    online activities. As a world-class{" "}
                    <b>digital marketing company</b>, we are committed to
                    helping any industries to achieve their online marketing
                    goals and stay ahead of the competition. Give our digital
                    marketing services to one chance and see a noticeable
                    improvement in your web traffic and sales.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                <img
                  src={idea}
                  alt="Web Design Services in Ahmedabad"
                  className="img-fluid mt-1"
                  width={500}
                />
              </div>
            </div>
          </div>
        </section>
        {/* --------------------------------------------------------------dreamproject-------------------- */}
        <Why />
        <div className="mt-5 mb-5">
          <WebCalculator />
        </div>

        {/* ------------------------ideas to reality------------------------------- */}
        
        {/* Blog Section */}
        <div className="mt-5 mb-5">
          {/* <BlogCard/> */}
          <Card />
        </div>
        {/* Blog Section */}
        
        <div className="gradient-bg pt-5 pb-5">
          <div className="container row  text-center pt-4 pb-4">
            <p className="top-para text-center text-white bigfont">
              Build Your Career With Our Internship Program</p>
                        <p className="text-white">Ready to dive into real-world tech? Our Internship Program offers hands-on experience with the latest technologies like React JS, Node JS, Laravel, Django, Python, and PHP. Join us to work on live projects that shape your future.</p>              
              <div className="d-flex justify-content-center ">
                <Link to="/contact-us">
                  <button className="gradient-bg-button  mb-2 col-12 col-lg-12 mt-3">
                   Join Our Team
                  </button>
                </Link>
                            </div>
            </div>
          </div>
    

        {/* ------------------------ideas to reality------------------------------- */}

        {/* <div className="col-12  time_banner">
        <h1 className="display-4 fw-bold text-center">
          Get your dream project done.
        </h1>
        <p className="display-4 fw-bolder time" >
          {ctime}
        </p>
        <button
          className="figure_btn btn py-3 px-5"
          onClick={navigateToContacts}
        >
          Get in touch with us &#8594;
        </button>
      </div> */}

        {/* <Slider /> */}

        {/* <Card /> */}
        <div >
          <Testimonials />
        </div>
        <Accordian />

         
      </Layout>
    </>
  );
};

export default Hero;
