import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";
import ecom1 from "../../assets/WebpImages/ecom1.webp";
import res from "../../assets/WebpImages/responsive-website.webp";

const WebDesignLosangeles = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in Los Angeles ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in Los Angeles",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Website Design in Los Angeles:",
      text: "We offer a variety of services to boost your online presence. Our Custom Website Design ensures your site captures your brand's essence, whether you prefer a modern look or something bold and innovative. For online sales, our Ecommerce Website Development provides user-friendly and secure platforms tailored to your needs. If your business requires a custom solution, our Web Application Development can craft the perfect application to streamline processes. To amplify your brand's voice, our Social Media Marketing delivers strategic campaigns that reach new customers. Our SEO Services enhance your website's search engine ranking, driving organic traffic with data-driven strategies. If your website needs a refresh, our Website Redesign offers modern designs that improve user experience and boost conversions. Finally, our UI/UX Design focuses on creating intuitive interfaces that guide visitors toward your desired actions.",
    },
   
    
    // more content objects
  ];

  const ctaContent = {
    text: "Ready to take your Los Angeles business to the next level?",
    para : "Contact us today for a free consultation and discover how our website design and development services can help you achieve your online goals.",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 0;

  const textSections = [
    {
        index: 0,
        heading: "Best Website Designer for Your Los Angeles Business",
        text: "At Bytefaze, we are a passionate web design and development agency headquartered in India, with a global reputation for crafting high-impact web solutions. We're excited to bring our expertise to the vibrant business landscape of Los Angeles. We understand the unique challenges and opportunities that Los Angeles businesses face in today's competitive online environment. Our team of skilled professionals is dedicated to collaborating with you to create a website that not only looks amazing but also effectively attracts, engages, and converts your target audience.",
      },
    {
      index: 0,
      heading: "Why Choose Us?",
      text: "<ul><li><b>Affordable Excellence </b>We combine India's competitive pricing with world-class expertise, ensuring you receive exceptional value for your investment.</li><li><b>Custom Web Solutions:</b> We don't believe in one-size-fits-all solutions. We take the time to understand your unique business goals and create a website that perfectly aligns with your brand identity and target audience.</li><li><b>Responsive Design Expertise:</b> In today's mobile-first world, your website needs to be accessible and user-friendly on any device. We specialize in responsive design, ensuring your website looks stunning and functions flawlessly across all platforms.</li><li><b>Dedicated Team of Experts: </b> Our team of experienced designers, developers, and strategists are passionate about creating impactful web experiences.</li></ul>",
    },
    
    // Add as many text sections as needed
  ];

  const linksData = [
    {
      text: "Website Redesign Company in Boston",
      url: "/web-design-boston",
    },
    { text: "Custom Web Design Services in San Francisco", url: "/website-design-in-san-francisco" },
    { text: "SEO-Freindly Website Designing Agency in London", url: "/web-design-development-in-london" },
    { text: "Mobile Application Development in Paris ", url: "/web-design-paris" },
    { text: "Affordable Web Designing Solutions in Newyork ", url: "/website-design-development-in-newyork" },
    
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Best Website Designer in L.A",
    "Web Development Company in USA",
    "Best Web Designing Services in Los Angeles","Professional Website Development Los Angeles","Creative Website Developer in Los Angeles","SEO-friendly Website Design in Los Angeles", "SEO-friendly Website Design Agency in Los Angeles","Website Maintainace in Los Angeles"
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Web Design in Los Angeles | Best Website Redesign Services in L.A"}
      keywords={
        "Custom Web Development Agency, Responsive Website Designing, Web Developer in Los Angeles, Best Website Redesign Services in L.A, Website Designer Company"
      }
      description={
        "Bytefaze is top-rated web design agency in Los Angeles. We specialize in develop affordable, custom web design, responsive & seo-friendly mobile applications development that drive results. Contact us today for our services cost."
      }
      canonical={"https://www.bytefaze.com/web-design-development-in-losangeles"}
      ldName={"Website Design & Development in Los Angeles"}
      serviceType={"Web Designing & Development Service in Los Angeles"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignLosangeles;
