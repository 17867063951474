import React from "react";
import Layout from "../../layout/Layouts";
import img1 from "../../../assets/WebpImages/website-designer-near-me.webp";
import { Link } from "react-router-dom";
import Card from "../Card";
import logo from "../../../assets/WebpImages/bytedark.webp";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const WebsiteDesignerNearMe = () => {
  const data = {
    tag: "Website Designer Near Me",
    description:
      "Looking for a professional website designer near you? Get creative website design and expert website development services with local web developers. Discover how to boost your online presence today!",
    keywords:
      "Website Designer Near Me, Website Developer Near Me, Website Designing Company Near Me, Local Web Developer, SEO for Local Businesses, Local Market Insights, Popular Internet Website Designer, Local Web Developer, Creative Web Design, Affordable Website Design Near Me, Website Maker in Ahemdabad",
    img: img1,
    altTag: "Best Local Website Designer Near Me", //image Alt tag
    label: "Website Designer Near Me",
    title: "Top Website Designer Near Me - Creative Web Design & Local Expertise | Bytefaze",
    PageTitle: "Why Hiring a Website Designer Near Me is Essential for Your Business Success",
    date: "26 SEP 2024",
    desc: (
      <p className="lead">
      In today’s digital age, having a strong online presence is non-negotiable for In today's digital landscape, having a well-designed website is crucial for any business aiming to reach its target audience effectively. If you're searching for a <b>website designer near me</b>, it's essential to understand how local expertise can enhance your online presence. Bytefaze, a top-rated local web development company, specializes in crafting websites that resonate with your regional market. With a focus on in-person consultations and personalized services, Our company ensures that your vision is not only realized but also optimized for success.
      </p>
    ),
    img2: img1,
    points: (
      <>
        <div className="container w-75">
          <div className="row  item-align-center col-md-12 text-center">
            <table className="table border">
              <thead>
                <tr>
                  <th style={{ color: "var(--link-color)" }}>
                  Need help finding a Web Designer?
                  </th>
                </tr>
              </thead>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#importance-of-local-designers">
                  The Importance of Local Designers
                  </a>
                </td>
              </tr>
                            <tr>
                <td className="pt-1 pb-1">
                  <a href="#top-benefits-of-local-website-designer">
                  Top Benefits of Choosing a Local Website Designer
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#website-designing-services-by-local-designers">
                  Website Designing Services by Local Designers

                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#why-local-website-designer-provide-best-solutions">
                  Why Local Website Designers Provide the Best Solutions


                  </a>
                </td>
              </tr>
              
            </table>
          </div>
        </div>
        {/* point1 */}
        <div className="top-para">
          <h2
            style={{ color: "var(--link-color)" }}
            id="importance-of-local-designers"
          >
           The Importance of Local Designers
          </h2>
          <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "10%",
                    margin: "20px",
                    opacity: "1",
                  }}
                />
         <p className="top-para">Choosing a <b><Link to="/">local web developer</Link></b> comes with unique advantages. Local designers have a better understanding of the local culture, market trends, and customer preferences. This insight enables them to create websites that truly reflect the needs and expectations of your target audience. Furthermore, working with someone nearby fosters a sense of trust and communication, allowing for seamless collaboration throughout the design process.</p>
    

          {/* point2 */}

          <h3
            style={{ color: "var(--link-color)" }}
            id="top-benefits-of-local-website-designer"
          >
           Top Benefits of Choosing a Local Website Designer
          </h3>
          <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "10%",
                    margin: "20px",
                    opacity: "1",
                  }}
                />

          <ol>
            <li className="mb-2"><b>Seamless Communication:</b> </li>
            <p className="top-para mb-2">Effective communication is the cornerstone of any successful project. When you choose Bytefaze as your <b>local web developer</b>, you benefit from direct access to your designer. This ensures that your ideas are conveyed clearly and adjustments can be made in real time. Regular meetings and consultations enable you to provide feedback and make decisions quickly, leading to a more efficient design process.</p> 
            <li className="mb-2"><b>Local Market Insights:</b> </li>
            <p className="top-para mb-2">Bytefaze’s team possesses a wealth of knowledge about local market dynamics. They can identify what resonates with your audience, helping you tailor your website to meet local needs. This insight not only enhances user engagement but also ensures that your site effectively promotes your products or services to those who are most likely to convert.</p>
            <li className="mb-2"><b>Rapid Support:</b> </li>
            <p className="top-para mb-2">In the fast-paced digital world, time is of the essence. Having a local designer means you can receive prompt support and troubleshooting when issues arise. Bytefaze is dedicated to providing quick assistance, minimizing downtime, and ensuring your website remains functional and effective at all times.</p>
            <li className="mb-2"><b>Custom Website Designing Services:</b></li>
            <p className="top-para mb-2"> Bytefaze offers <b>website designing services</b> that prioritize your business’s unique needs. Unlike template-based designs, a custom approach allows for greater flexibility and personalization. Whether you’re looking for a corporate site, an e-commerce platform, or a personal blog, Bytefaze crafts solutions that reflect your brand's identity and engage your audience.</p>
            <li className="mb-2"><b>SEO Optimization:</b></li> 
            <p className="top-para mb-2">A beautifully designed website is just the beginning; it must also be discoverable. Bytefaze integrates effective SEO strategies into your <b>creative web design</b> projects, ensuring your site is set up to rank well in search results. From keyword optimization to meta tags and on-page SEO, their expertise can significantly improve your site’s visibility, driving organic traffic to your business.</p>
            </ol>
            
          {/* point3*/}
          <h4 style={{ color: "var(--link-color)" }} id="how-to-find-best-website-designer-near-me">
          How to Find the Best Website Designer Near Me
          </h4>
          <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "10%",
                    margin: "20px",
                    opacity: "1",
                  }}
                />
          <p>When searching for the right <b> website designer near me</b>, consider the following key factors:</p>
         <ol>
            <li className="mb-2"><b>Portfolio Evaluation</b> </li>
            <p className="top-para mb-2">Reviewing a designer's portfolio is a crucial step in understanding their style and capabilities. Look for a diverse range of projects that demonstrate their ability to create engaging and functional websites. This will give you confidence in their ability to deliver a site that meets your specific needs. </p>
            <li className="mb-2"><b>Client Testimonials</b></li> 
            <p className="top-para mb-2">Client reviews and testimonials offer valuable insights into a designer's reliability and quality of work. Take the time to read feedback from previous clients to gauge their satisfaction and experience. Positive reviews can provide reassurance that you’re making the right choice.</p>
            <li className="mb-2"><b>Industry Understanding</b></li> 
            <p className="top-para mb-2">Ensure that your chosen designer has a strong grasp of your specific industry. A designer who understands the nuances of your field will be better equipped to create a website that effectively communicates your brand message and meets your audience's expectations.</p>
            <li className="mb-2"><b>Communication Style</b> </li>
            <p className="top-para mb-2">Assess the designer's communication style during your initial consultations. Are they responsive, attentive, and willing to listen to your ideas? A designer who values your input and fosters open communication will contribute to a more successful collaboration.</p>
            </ol>
          {/* point6*/}
          <h3 id="website-designing-services-by-local-designers">Website Designing Services by Local Designers</h3>
          <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "10%",
                    margin: "20px",
                    opacity: "1",
                  }}
                />
          <p>Bytefaze offers a wide range of <b>website designing services</b>, ensuring that every aspect of your online presence is covered:</p>
         
          <ol>
            <li className="mb-2"><b>Creative Website Design</b></li> 
            <p className="top-para mb-2">Bytefaze’s design team is dedicated to creating visually stunning websites that capture the essence of your brand. They prioritize user experience, ensuring that visitors can navigate your site effortlessly. A well-designed website enhances user engagement, ultimately leading to higher conversion rates.</p>
            <li className="mb-2"><b>E-commerce Solutions </b></li>
            <p className="top-para mb-2">If you’re looking to establish an online store, Bytefaze provides tailored e-commerce solutions. From designing user-friendly interfaces to integrating secure payment gateways, they ensure your e-commerce platform is optimized for both performance and security. This allows you to focus on growing your business while they handle the technical aspects.</p>
            <li className="mb-2"><b>SEO Integration</b> </li>
            <p className="top-para mb-2">Effective SEO strategies are essential for driving traffic to your website. Bytefaze incorporates SEO best practices into every project, ensuring your site is optimized for search engines from the outset. This includes keyword research, on-page optimization, and content strategies that align with industry standards.</p>
            <li className="mb-2"><b>Responsive Designs for All Devices</b></li>
            <p className="top-para mb-2">With the majority of users accessing websites via mobile devices, having a responsive design is crucial. Bytefaze ensures that your website looks and functions perfectly across all devices, enhancing user experience and boosting your site’s performance in search rankings.</p>
            <li className="mb-2 mt-2"><b>Local SEO Services</b>
            <p className="top-para mb-2">In addition to general SEO strategies, Bytefaze emphasizes the importance of local SEO. By optimizing your website for local searches, you can attract more customers from your area. Key components include:</p>
            <ul>
                <li className="mb-2"><b>Google My Business Optimization:</b> Establishing and optimizing your Google My Business profile to increase visibility in local search results.</li>
                <li className="mb-2"><b>Local Keyword Research:</b> Establishing and optimizing your Google My Business profile to increase visibility in local search results.</li>
                <li className="mb-2"><b>Citations and Reviews:</b> Managing online reviews and citations to enhance your credibility and trustworthiness in the local market.</li>
            </ul>
            <p><b>Why Local SEO Matters:</b> Investing in local SEO can significantly boost your visibility in search results, driving more traffic to your website and increasing foot traffic to your physical location.</p>
            <p>Explore the advantages of local SEO! Check out our blog on the <b><Link to="/blog/website-design-services-for-local-businesses-grow-your-local-reach/">benefits of local SEO services</Link></b> to learn how effective strategies can enhance your online visibility, attract more local customers, and ultimately drive business growth.</p>
            </li>
          </ol>

          <h4 id="why-local-website-designer-provide-best-solutions">Why Local Website Designers Provide the Best Solutions</h4>
          <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "10%",
                    margin: "20px",
                    opacity: "1",
                  }}
                />
                <p>Choosing a <b>local web designer</b> like Bytefaze comes with distinct advantages:</p>
          <ul>
            <li className="mb-2"><b>Personalized Approach:</b> Bytefaze takes the time to understand your business, tailoring solutions that fit your specific needs.</li>
            <li className="mb-2"><b>Community Connection:</b> As a local company, Bytefaze is invested in the success of businesses in the community, ensuring that their services are aligned with local market demands.</li>
            <li className="mb-2"><b>Trust and Reliability:</b> Working with a local designer fosters a sense of trust, as you can easily meet in person and build a professional relationship that lasts.</li>
          </ul>
          <p>In conclusion, <b><Link to="/contact-us" title="Get Quote from Best Website Designer Near You">hiring a website designer near me</Link></b> is a strategic choice for businesses aiming to enhance their online presence. Bytefaze’s commitment to quality, personalized service, and local expertise makes them an ideal partner for your web design needs. By choosing Bytefaze, you’re not just getting a website; you’re investing in a valuable asset that can help drive your business success.</p>
         
        </div>
        <div className="container mt-3 mb-5">
            <h2 className="text-center mt-4 top-para">
              Popular Cities 
            </h2>
            <hr
              style={{
                borderTop: "3px solid #ffc107",
                width: "100px",
                margin: "20px auto",
                opacity: "1",
              }}
            />

            <div className="row">
              {[
                {
                  href: "/web-designing-in-ankleshwar",
                  text: "Ankleshwar",
                },
                {
                  href: "/web-designing-in-bhavnagar",
                  text: "Bhavnagar",
                },
                {
                  href: "/web-designing-in-gandhinagar",
                  text: "Gandhinagar",
                },
                {
                  href: "/web-designing-in-baroda",
                  text: "Baroda",
                },
                {
                  href: "/web-designing-in-rajkot",
                  text: "Rajkot",
                },
                {
                  href: "/web-designing-in-surat",
                  text: "Surat",
                },
                {
                  href: "/web-designing-in-jamnagar",
                  text: "Jamnagar",
                },
                {
                  href: "/web-designing-in-mehsana",
                  text: "Mahesana",
                },
                {
                  href: "/web-designing-in-morbi",
                  text: "Morbi",
                },
                {
                  href: "/web-designing-in-mumbai",
                  text: "Mumbai",
                },
                { 
                    href: "/web-designing-in-pune", 
                    text: "Pune" 
                },
              ].map((item, index) => (
                <div
                  className="col-lg-4 col-md-6 col-12 mb-2 d-flex align-items-center justify-content-center text-center"
                  key={index}
                >
                  <a
                    href={item.href}
                    className="card-link border-dotted rounded-card hover-effect w-100 d-flex align-items-center justify-content-center"
                  >
                    <div className="p-2">
                      <p className="mb-0">{item.text}</p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
      </>
    ),
    lastp:
      "",

    Fieldtags: (
      <>
        <div className="container mt-1">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-wrap">
                <Link to="/" className="btn btn-outline-secondary m-1">
                Local Website Designer Near Me
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Website Developer Near Me
                </Link>
                <Link
                  to="/responsive-website"
                  className="btn btn-outline-secondary m-1"
                >
                  Hire Local Website Designing Company

                </Link>
                <Link
                  to="/digital-marketing"
                  className="btn btn-outline-secondary m-1"
                >
                  Best Local Website Design Near Me
                </Link>
                <Link to="/" className="btn btn-outline-secondary m-1">
                  Top Web Designer Near Me
                </Link>
                <Link to="/" className="btn btn-outline-secondary m-1">
                  Local Web Development Company in Ahmedabad
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };

  return (
    <Layout
      title={data?.title}
      keywords={data?.keywords}
      description={data?.description}
      canonical={
        "https://www.bytefaze.com/blog/why-hiring-website-designer-near-me-is-essential-for-business"
      }
      ldName={"Website Designing For SEO"}
      ldImage={img1}
      serviceType={"Website Designing For SEO"}
    >
      {/* <!-- Featured 1 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <div
            className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between"
            style={{ color: "var(--link-color)" }}
          >
            <div className="col-12 col-lg-7 order-1 order-lg-0">
              <div className="entry-header mb-3">
                <ul className="entry-meta list-unstyled d-flex mb-3">
                  <li className="mb-2">
                    <a
                      className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                      href="#"
                    >
                      {data?.label}
                    </a>
                  </li>
                </ul>
                <h1 className="display-3 fw-bold mb-4">{data?.PageTitle}</h1>
              </div>
              <div className="entry-footer">
                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                  <li className="mb-2">
                    <a
                      className="fs-7  text-decoration-none d-flex align-items-center"
                      href="#"
                    >
                      <svg
                        xmlns="https://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fillRule="currentColor"
                        className="bi bi-calendar3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                      <span
                        className="ms-2 fs-7"
                        style={{ color: "var(--link-color)" }}
                      >
                        {data?.date}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <a
                className="d-block bsb-hover-image overflow-hidden rounded"
                href="#"
              >
                <img
                  className="img-fluid bsb-scale bsb-hover-scale-up"
                  src={img1}
                  alt="Website Designer Near Me"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Entry Content Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10">
          <div className="container">
            <div className="row justify-content-md-center gy-3 gy-xl-4">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.desc}
                {/* <p className="lead fw-bold">{data?.desc} <a href="#">emotional support</a> in a way that is healthy for both you and your partner.</p> */}
              </div>

              <div className="col-12 text-center">
                <img
                  className="img-fluid my-4 rounded"
                  src={img1}
                  alt="Best Local Website Developer Near Me"
                />
              </div>

              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.points}
                <p>{data?.points2}</p>
                {/* <h2 style={{color:"var(--link-color)"}}>Offer Physical Affection</h2> */}
                {/* <img  className="img-fluid mt-3 mb-5 rounded" src={img1} alt=""/> */}
                <p>{data?.lastp}</p>
                {/* <p>Different people like different levels of physical affection at different times. If you are feeling unsure, communicate with your partner so you can learn what they are open to receiving and can provide it accordingly.</p> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Entry Meta --> */}
        <div className="pb-3 pb-md-5 pb-xl-10">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <span
                  className="me-1 fw-bold"
                  style={{ color: "var(--link-color)" }}
                >
                  {" "}
                  Tags:
                </span>
                <ul className="entry-meta list-unstyled d-inline-flex align-items-center justify-content-center mb-3">
                  {data.Fieldtags}
                </ul>
              </div>
            </div>
          </div>
        </div>



        {/* <!-- Author 2 - Bootstrap Brain Component --> */}
        <section className="bsb-author-2 py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1X">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">
                <div className=" border-light-subtle p-4 border">
                  <div className="row gy-3 align-items-center">
                    <div className="col-md-4">
                      <a
                        href="#"
                        className="bsb-hover-image d-block rounded overflow-hidden"
                      >
                        <img
                          className="img-fluid author-avatar bsb-scale bsb-hover-scale-up"
                          src={logo}
                          alt="Website Designing Company"
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h6
                          className="card-subtitle mb-2  "
                          style={{ color: "var(--link-color)" }}
                        >
                          Article written by
                        </h6>
                        <h3 className="card-title mb-2">
                          <a
                            className="card-link  text-decoration-none"
                            href="#"
                          >
                            Bytefaze
                          </a>
                        </h3>
                        <p className="card-text mb-3">
                          We are skilled in all aspects of web design, from
                          planning and development to design and testing. I also
                          have experience in web development technologies.
                        </p>
                        <ul className="bsb-social-media nav m-0">
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
                            >
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-facebook"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                  <a className="nav-link " href="#">
                    <svg xmlns="https://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li> */}
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://x.com/Bytefaze"
                            >
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-twitter"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              href="https://www.linkedin.com/company/bytefaze/"
                            >
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1X ">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12">
                <h2
                  className="h4 mb-4 mb-md-5"
                  style={{ color: "var(--link-color)" }}
                >
                  Related Articles
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="container overflow-hidden px-4"> */}
          {/* <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}
          <Card />
          {/* </div> */}
          {/* </div> */}
        </section>

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2
                  className="display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis"
                  style={{ color: "var(--link-color)" }}
                >
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default WebsiteDesignerNearMe;
