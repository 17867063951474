import React from "react";
//import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";

import "../../Styles/Internship.css";
import Layout from "../layout/Layouts";

import php from "../../assets/WebpImages/php.webp";
import reactImg from "../../assets/WebpImages/reactImg.webp";
import python from "../../assets/WebpImages/python.webp";
import webdesign from "../../assets/Images/hc.png";

import django from "../../assets/Images/django.png";
import next from "../../assets/Images/next.png";
import webdev from "../../assets/Images/Logo.webp";
import { RiProjectorFill } from "react-icons/ri";
import { IoLocation } from "react-icons/io5";
import { GiDuration } from "react-icons/gi";
import { Link } from "react-router-dom";
const internships = [
  {
    title: "Python Internship",
    description: "Step by step process for Python interns.",
    duration: "0 - 6 Months",
    project: "with/without Project",
    location: "Ahmedabad",
    imageUrl: python,
  },
  {
    title: "Django Internship",
    description: "Step by step process for Django interns.",
    duration: "0 - 6 Months",
    project: "with/without Project",
    location: "Ahmedabad",
    imageUrl: django,
  },
  {
    title: "React Js Internship",
    description: "Step by step process for React Js interns.",
    duration: "0 - 6 Months",
    project: "with/without Project",
    location: "Ahmedabad",
    imageUrl: reactImg,
  },
  {
    title: "Web Designing Internship",
    description: "Step by step process for Web Designing interns.",
    duration: "0 - 6 Months",
    project: "with/without Project",
    location: "Ahmedabad",
    imageUrl: webdesign,
  },
  {
    title: "Next Js Internship",
    description: "Step by step process for Next Js interns.",

    duration: "0 - 6 Months",
    project: "with/without Project",
    location: "Ahmedabad",
    imageUrl: next,
  },
  {
    title: "Web Development Internship",
    description: "Step by step process for Web Development interns.",
    
    duration: "0 - 6 Months",
    project: "with/without Project",
    location: "Ahmedabad",
    imageUrl: webdev,
  },
  // Add more internships as needed
];

const Internship = () => {
  return (
    <Layout
      title={
        "Best IT Internship Company in Ahmedabad | Paid Live Project Training for Students"
      }
      keywords={
        "Internship Company Ahmedabad, Internship for IT Students, Internship for Computer Engineers, Web Designing Internship, Web Development Internship Programs, Paid Internship Ahmedabad, PHP Internship in Ahmedabad, React Js internship in Ahmedabad, Phython Internship in Ahmedabad, Project training in Ahmedabad, Project training Ahmedabad, Free project training, Free project training Ahmedabad, Live Project Training, Free live project training, MCA Project Training, BE Project Training, BCA Project Training, B.tec Project Training, MBA Project Training, Summer Internship for IT Students in Web Development"
      }
      description={
        "Bytefaze, top web development company in Ahmedabad, offers the best IT internship & training in React JS, PHP, Python, IOS, UI/UX Design for students. Join our internship program Now !"
      }
      canonical={"https://www.bytefaze.com/internships"}
      ldName={"Internship Company in Ahmedabad"}
      serviceType={"Internship & Training"}
      ldImage={php}
    >
      <div className="container ">
        <div className="p-5 rounded-lg ">
          <h1 className="top-para  text-center">Best Internship Company in Ahmedabad</h1>
          <hr
          style={{
            borderTop: "3px solid #ffc107",
            width: "10%",
            margin: "15px auto",
            opacity: "1",
          }} />
          <p className="text-muted mb-4 text-center mx-auto">
          Internship is a fundamental component of any IT courses and play a crucial role in bridging the gap between academic knowledge and practical experience. Welcome to Bytefaze, an esteemed <b><Link to="/">Web Developement Company</Link></b>, where we believe in nurturing talent and shaping future tech leaders. Our Internship Program offers an incredible opportunity for students and young professionals to gain hands-on experience, learn cutting-edge technologies, and work on real-world projects. Our supportive and enriching environment fosters your learning and growth.

          </p>
          <h3 className="top-para">3-Month Internship Program for College Students</h3>
        <hr
          style={{
            borderTop: "3px solid #ffc107",
            width: "10%",
            margin: "15px",
            opacity: "1",
          }} />
          <p className="text-muted">Accelerate your career with our 3-month internship program, tailored for college students. Gain hands-on experience, work on cutting-edge projects, and network with industry leaders. Our immersive program will equip you with the skills and knowledge needed to excel in your chosen field, giving you a competitive edge in the job market

</p>
<p>Are you a student or a recent graduate in Ahmedabad looking to:</p>
<ul className="text-muted">
  <li>Gain practical experience with real-world projects?</li>
  <li>Bridge the gap between theory and practical application?</li>
  <li>Build a strong portfolio to impress potential employers?</li>
  <li>Get a head start in your dream tech career?</li>
</ul>
<p className="text-muted">If you answered yes, then you are on right place. Our online and offline Internship & Training with certificate Program is the perfect opportunity for you!</p>
        
           <h3 className="top-para">Who Should Apply?</h3>
        <hr
          style={{
            borderTop: "3px solid #ffc107",
            width: "10%",
            margin: "15px",
            opacity: "1",
          }} />
         <div className="row">
          <div className="col-6">
          <ul>
            <li>Engineering Students</li>
            <li>Last Semester Collage Students</li>
            <li>BE Students</li>
            <li>Computer Science Students</li>
            <li>Freshers</li>
            <li>PGDCA Students</li>
          </ul>
          </div>
          <div className="col-6">
          <ul>
            <li>B.Tech Students</li>
            <li>BSC IT Students</li>
            <li>MSC IT Students</li>
            <li>BCA Students</li>
            <li>MCA Students</li>
          </ul>
          </div>
         </div>
          <section className="who-we-are ">
          <div className="container w-100">
            <h3 className="text-center pt-1 mb-4">Benefits of the Internship</h3>
            <hr
          style={{
            borderTop: "3px solid #ffc107",
            width: "10%",
            margin: "15px auto",
            opacity: "1",
          }} />
            <div className="row" style={{color:"#061949"}}>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-xl">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Learn the latest technologies and best practices</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Showcase your work to potential employers</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Network with professionals in the field</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Develop the skills needed to succeed in web development</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Work on real-world projects and gain hands-on experienc</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Receive a certificate upon completion of the internship</p>
                </div>
              </div>
            </div>
          </div>
        </section>
          <h3 className="top-para">Pick A Internship To Get Started</h3>
        <hr
          style={{
            borderTop: "3px solid #ffc107",
            width: "10%",
            margin: "15px",
            opacity: "1",
          }} />
          <div className="row">
            {internships.map((internship, index) => (
              <div className="col-12 col-md-12 mb-4 " key={index}>
                <div className="card    intern-cardss shadow-lg h-100 border-0 ">
                  <div className="card-body d-flex flex-column flex-md-row align-items-center">
                    <img
                      src={internship.imageUrl}
                      alt={`${internship.title} Logo`}
                      className="me-md-3 mb-3 mb-md-0"
                      width="170"
                      height="170"
                    />
                    <div className="flex-grow-1 ">
                      <h5 className="card-title">{internship.title}</h5>
                      <p className="text-muted top-para small">
                        {internship.description}
                      </p>
                      <div className=" d-md-flex gap-5 flex-wrap ">
                        <div className="text-muted "><GiDuration className="text-warning"/> {internship.duration}</div>
                        <div className="text-muted">
                          <RiProjectorFill className="text-success"/> {internship.project}
                        </div>
                        <div className="text-muted"><IoLocation className="text-danger" /> {internship.location}</div>
                      </div>
                    </div>
                    <button className="button-33 mt-3 mt-md-0">
                      APPLY NOW
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Internship;