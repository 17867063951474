import React from "react";

// import accordiamimg from "../../assets/Images/accordianimg.png";
// import "../../Styles/Hero.css";

const Accordian = () => {
  return (
    <>
    
      <section className="py-3 py-md-5">
        <div className="container">
          <div className="row gy-5 gy-lg-0 justify-content-xl-center">
          <div className="col-12 col-xl-11" >
                  <h3
                    className="  text-capitalize text-center"
                    style={{ color: "var(--link-color)" }}
                  >
                  " Web Design & Development Services FAQ's "
                  </h3>
                  <div className="row">
                    <div className="col-lg-6">
                    <div className="accordion accordion-flush" id="accordionExample" >
                    <div className="accordion-item">
                    <h5 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"

                      >
                        What services do you offer?
                      </button>
                    </h5>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="top-para mb-2">
                        We offer a wide range of services including <b>custom Website development & designing, web and mobile app development, UI/UX design website</b>, quality assurance, and maintenance & support.
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                      <h5 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                         What Platforms Do You Work With ?
                        </button>
                      </h5>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          <div className="
                          top-para mb-2">
                          As a scalable web development company, we utilize the latest technologies like the MERN stack to complete your projects. Our specializations include React JS, Node JS, and the Django framework.

                          </div>
                         
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h5 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFourr"
                          aria-expanded="false"
                          aria-controls="collapseFourr"
                        >
                          What is the cost of your Website Development Services?
                        </button>
                      </h5>
                      <div
                        id="collapseFourr"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Our basic website design packages start from ₹10,000 and go up to ₹1,00,000, depending on the complexity and requirements of your project. For an accurate price, please feel free to contact us
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h5 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                         Why Should i make my website from Bytefaze ? 
                        </button>
                      </h5>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          <div  className="top-para mb-2"> 
                          Choosing Bytefaze for your website development is a decision you won’t regret. Our team is made up of passionate professionals who bring a wealth of experience in web design and development to the table. We know that every business is unique, which is why we take the time to understand your specific needs and tailor our solutions just for you. Using the latest technologies, including the React JS and Django framework, we create modern, Responsive websites that not only look great but also function seamlessly. What truly sets us apart is our commitment to you. We prioritize open communication throughout the entire process, ensuring that your vision is at the forefront of everything we do. Plus, our proven track record speaks volumes—our portfolio showcases a variety of successful projects and happy clients. With Bytefaze, you’re not just getting a service; you’re gaining a partner dedicated to elevating your online presence and helping your business thrive
                          </div>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h5 className="accordion-header" id="headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                         How long does it take to design and develop a website?
                        </button>
                      </h5>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          The time required to design and develop a website depends on various factors such as the complexity of the project, the number of pages, and the client's requirements. Typically, it can take anywhere from a few weeks to a few months to complete a website.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="accordion accordion-flush" id="accordionExample" >
                    <div className="accordion-item">
                      <h5 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          What is website design?
                        </button>
                      </h5>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Website design is the process by which the look and feel of a website are created. This includes the layout, colors, typography, and imagery of the website.

                        </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h5 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          What is the difference between normal web design and responsive website design?
                        </button>
                      </h5>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Normal web design is a process by which a website is created that is tailored to the specific needs of a business. Responsive website design is a process by which a website is created that can be viewed on all devices, including desktops, tablets, and smartphones.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h5 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Who is the Best Developer in Ahmedabad ?
                        </button>
                      </h5>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          The answer of these question might be tricky but you can agree after make website with us that Bytefaze is best Web Developer in Ahmedabad. You can check our portfolios, client testimonials, and our services to see why we’re the ideal choice for your project.                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h5 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                         Will AI be Used to Generate my Website ? 
                        </button>
                      </h5>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          We don't used AI for any Website Design or web Developer but if you don't want to pay charges of content writer than we can used AI to create your website data with your permission.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h5 className="accordion-header" id="headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                         Will my website be mobile-friendly?
                        </button>
                      </h5>
                      <div
                        id="collapseNine"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Absolutely! As a professional website development company, we guarantee that your website will feature a responsive web design. This means your site will automatically adapt to various screen sizes and devices, providing an optimal viewing experience for all users. Whether your visitors are accessing your site on a desktop, tablet, or smartphone, they will enjoy seamless navigation and accessibility
                          </p>
                        
                        </div>
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Accordian);
