import React, { useState } from "react";
import Layout from "../layout/Layouts";
import "./blog.css";
import img1 from "../../assets/WebpImages/about-img1.webp";
// import BlogCard from './BlogCard'
import { Link } from "react-router-dom";
import { blogData } from "./Card";

const Blog = () => {
  const [page, setPage] = useState(1);

  const itemsPerPage = 9;
  let reversedData = blogData.reverse();

  const handlePrevClick = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil((reversedData = reversedData.length / itemsPerPage))
      )
    );
  };

  // Calculate the current items to display based on the current page
  const currentItems = (reversedData = reversedData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  ));

  return (
    <Layout
      title={"Website Development & Designing From Scratch"}
      //keywords={"Digital Marketing Company in Ahmedabad, Digital Marketing Agency in Ahmedabad, Digital Marketing, Digital Marketing Services in Ahmedabad, Internet Marketing Agency, 360o Digital Marketing, Digital Experts in Ahmedabad, Digital Consultant in Ahmedabad, Digital Marketing Firms in Ahmedabad, Digital Data Driven Agency "}
      description={
        "How to Develop Website From Scratch ? Learn Web Development With Bytefaze is a Top IT Company in Ahmedabad offers fanstastic Web Designing Services"
      }
      canonical={"https://www.bytefaze.com/blog"}
      ldName={"Website Development From Scratch"}
      ldImage={
        "https://www.bytefaze.com/static/media/about-img1.6d5c57e0fdfca7d68e90.webp"
      }
    >
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <article>
            <div className="card ">
              <div className="row g-0">
                <div className="col-12 col-md-6 order-1 order-md-0 d-flex align-items-center">
                  <div className="card-body p-md-4 p-xl-6 p-xxl-9">
                    <div className="entry-header mb-3">
                      <ul className="entry-meta list-unstyled d-flex mb-4">
                        <li>
                          <a
                            className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                            href="#"
                          >
                            Entrepreneurship
                          </a>
                        </li>
                      </ul>
                      <h1 className="card-title entry-title display-3 fw-bold mb-4 lh-1">
                        How to Design and Develop Websites from Scratch
                      </h1>
                    </div>
                    <p className="card-text entry-summary  mb-4">
                      Creating a successful websites from scratch is the stuff
                      that entrepreneurial dreams are made of, but it's a
                      journey that's equal parts challenging and thrilling.
                    </p>
                    <div className="entry-footer">
                      <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                        <li>
                          <a
                            className="fs-7  text-decoration-none d-flex align-items-center"
                            href="#"
                          >
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="currentColor"
                              className="bi bi-calendar3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                              <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                            </svg>
                            <span className="ms-2 fs-7">13 Apr 2023</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex">
                  <img
                    className="img-fluid rounded-end object-fit-cover"
                    src={img1}
                    alt="Website Designing Company Ahmedabad"
                  />
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
      <main id="main">
        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}

        <div className="container">
          <div className="row ">
            {currentItems &&
              currentItems.map((el, i) => (
          <div className="col-md-6 col-lg-4 ">
  <div key={i} className="card border-0 bg-transparent h-100">

    {/* Image at the top */}
    <div className="card-img-top mb-4 overflow-hidden bsb-overlay-hover">
      <Link to={`/blog/${el.link}`}>
        <img
          className="img-fluid bsb-scale bsb-hover-scale-up"
          src={el.img}
          alt={el.altTag}
          style={{ height: 300, width: "100%", objectFit: "cover" }} // Ensuring uniform image size
        />
      </Link>
      <figcaption>
        <svg
          xmlns="https://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          className="bi bi-eye bsb-hover-fadeInLeft"
          viewBox="0 0 16 16"
        >
          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
        </svg>
        <Link to={`/blog/${el.link}`}>
          <h4 className="h6 bsb-hover-fadeInRight mt-2">Read More</h4>
        </Link>
      </figcaption>
    </div>

    {/* Title and Description */}
    <div className="card-body  d-flex flex-column justify-content-between">
      <div>
        <div className="entry-header mb-3">
          <ul className="entry-meta list-unstyled d-flex mb-3">
            <li>
              <Link
                className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                to={`/blog/${el.link}`}
              >
                {el.tag}
              </Link>
            </li>
          </ul>
          <h2 className="card-title entry-title h4 m-0">
            <p className="text-decoration-none">{el.title}</p>
          </h2>
          <p className="mt-2">
            {JSON.stringify(el?.desc).slice(74, 244) + "..."}
            <Link style={{ color: "#5755FE" }} to={`/blog/${el.link}`}>
              Know More
            </Link>
          </p>
        </div>
      </div>

      {/* Footer with Date at the Bottom */}
      <div className="card-footer border-0 bg-transparent p-0 m-0 mt-auto">
        <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
          <li>
            <a
              className="fs-7 text-decoration-none d-flex align-items-center"
              href="#"
            >
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                className="bi bi-calendar3"
                viewBox="0 0 16 16"
              >
                <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
              <span className="blog-date ms-2 fs-7 my-2">{el.date}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>

              ))}
            <div className="container d-flex justify-content-center mt-5">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                    <button
                      className="page-link"
                      onClick={handlePrevClick}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                  </li>
                  {[...Array(Math.ceil(blogData.length / itemsPerPage))].map(
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          page === index + 1 ? "active" : ""
                        }`}
                        onClick={() => setPage(index + 1)}
                      >
                        <button className="page-link">{index + 1}</button>
                      </li>
                    )
                  )}
                  <li
                    className={`page-item ${
                      page === Math.ceil(blogData.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handleNextClick}
                      disabled={
                        page === Math.ceil(blogData.length / itemsPerPage)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1">

          <Card />
      
        </section> */}

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2 className="card-title display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis">
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Blog;
