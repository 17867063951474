import React, { useEffect } from "react";
//import { LazyLoadImage } from "react-lazy-load-image-component";
import Layout from "../layout/Layouts";
// import "../../Styles/Services.css";
// import table from "../../assets/WebpImages/table.webp";

import webdesign from "../../assets/WebpImages/static-website-designer.webp";
import web2 from "../../assets/WebpImages/static-web-design.webp";
import web3 from "../../assets/WebpImages/static-website-design.webp";
import web4 from "../../assets/WebpImages/static-web-developer.webp";
import { Link, useLocation } from "react-router-dom";
import {
  FaLaptop,
  FaBusinessTime,
  FaCogs,
  FaMobileAlt,
  FaGlobe,
  FaShoppingCart,
  FaExchangeAlt,
  FaFileAlt,
  FaUser,
} from "react-icons/fa";

const designTypes = [
  { icon: <FaLaptop />, title: "Blazing Fast Speed " },
  { icon: <FaBusinessTime />, title: "High Security" },
  { icon: <FaCogs />, title: " Cost-Effective" },
  { icon: <FaMobileAlt />, title: "SEO-Friendly" },
  { icon: <FaGlobe />, title: "Easy Deployment" },
  { icon: <FaShoppingCart />,     title: "Scalability"  },
  { icon: <FaExchangeAlt />, title: "Cross-Browser Compatibility" },
  { icon: <FaFileAlt />, title: "Reliable Performance" },
  { icon: <FaUser />, title: "Minimal Maintenance" }, 
];
const StaticWebsite = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); // Remove the '#' to get the id
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    <Layout
      title={"Static Website Design & Development Company in Ahemdabad, India - Bytefaze"}
      keywords={
        "Static Website Design, Static Web Developer, Static Website Designer, Static Website Maintainace & support Services, Static Website Ahmedabad, Static Website Development Company"
      }
      description={
        "Bytefaze offers Static Website Design & Development Services in Ahmedabad, Gujarat, India. A static website have web pages with fixed content, need Web Developer and Website Desiner to make changes. Contact Now !" }
      canonical={"https://www.bytefaze.com/static-web-design-development-services"}
      ldName={"Static Web Designing & Development"}
      ldImage={webdesign}
      serviceType={"Static Website Designing & Development Service"}
    >
     <section className="container my-5">        
        <div className="row align-items-center">
          <div className="col-lg-6 text-center text-lg-start order-lg-1 order-2">
            <h1 className="fw-bold mb-3">
              Static Website Design & Development
            </h1>
            <p itemscope itemtype="https://schema.org/BreadcrumbList">
  <span itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
    <a itemprop="item" href="/">
      <span itemprop="name">Home</span>
    </a>
    <meta itemprop="position" content="1" />
  </span> / 
  <span itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
    <a itemprop="item" href="/what-we-do">
      <span itemprop="name">Services</span>
    </a>
    <meta itemprop="position" content="2" />
  </span> /
  <span itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
    <a itemprop="item" href="/static-web-design-development-services">
      <span itemprop="name">Static Web Design</span>
    </a>
    <meta itemprop="position" content="3" />
  </span>
</p>
            <p className="col-12  mx-auto mx-lg-0 top-para">Make a business page, portfolio, landing page, or complex website with our <b>Static Website Development & Designing Services </b>to unlock the power of simplicity 
            </p>
                        <div className="d-flex justify-content-center justify-content-lg-start">
              <Link to="/contact-us">
                <button className="button-33 btn-lg px-5 mb-2 mt-3">
                  Get A Quote
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 mb-4 mb-lg-0 order-lg-2 order-1">
            <img
              src={web2}
              alt="Static Web Design"
              className="img-fluid rounded gif-transparent "
            />
          </div>
        </div>
      </section>

      <section>
        <h2 className="text-center mt-5 top-para">
          Best Static Website Developer in India
        </h2>
        <hr
          style={{
            borderTop: "3px solid #ffc107",
            width: "100px",
            margin: "20px auto",
            opacity: "1",
          }}
        />

        <div className="container">
          <div className="container mt-3" id="best-static-website-developer">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-lg-6 col-md-12 mb-4">
                <img src={web3} alt="Static Website Designing" className="img-fluid" />
              </div>
              <div className="col-lg-6 col-md-12">
                <p className="top-para">
                Our company offers an expert static web design and development services tailored to deliver high-performance, secure, and aesthetically pleasing websites. Bytefaze as a leading <b><Link to="/">static web development company</Link></b>, specialize in creating fast-loading websites that are perfect for businesses seeking simple yet effective online solutions. Our skilled and experianced website developers are proficient in designing <b>static websites</b> that cater to your specific needs while ensuring your brand’s online presence stands out.
                </p>
               
              </div>
            </div>
          </div>
          <section>
          <div style={{ background: "var(--footer-bg)" }}  className="row mt-5" >
              <div className="container row  text-center pt-5 pb-5">
              <h2 className="top-para text-center mb-3">Ready to Transform Your Online Presence ? Let's Build Your Dream Static Website Together</h2>
                <div className="d-flex justify-content-center">
                  <Link to="/contact-us">
                    <button className="button-33 mb-2 col-12 col-lg-12 mt-3">Get A Free Quote Now! </button>
                  </Link>
                </div>

              </div>
            </div>
            </section>
          <div className="container mt-3 mb-5">
            <h2 className="text-center mt-4 top-para">
              Quick Static Web Design & Development Service Finder
            </h2>
            <hr
              style={{
                borderTop: "3px solid #ffc107",
                width: "100px",
                margin: "20px auto",
                opacity: "1",
              }}
            />

            <div className="row">
              {[
                {
                  href: "#best-static-website-developer",
                  text: "Best Static Website Developer India",
                },
                {
                  href: "#why-choose-static-web-design",
                  text: "Why Choose Static Web Design?",
                },
                {
                  href: "#static-web-development-services",
                  text: "Static Web Development Services",
                },
                {
                  href: "#why-works-with-bytefaze",
                  text: "Why Work with Bytefaze ?",
                },
                {
                  href: "#custom-static-website",
                  text: "Custom Static Website",
                },
               
              ].map((item, index) => (
                <div
                  className="col-lg-4 col-md-6 col-12 mb-2 d-flex align-items-center justify-content-center text-center"
                  key={index}
                >
                  <a
                    href={item.href}
                    className="card-link border-dotted rounded-card hover-effect w-100 d-flex align-items-center justify-content-center"
                  >
                    <div className="p-2">
                      <p className="mb-0">{item.text}</p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div
            className="row justify-content-center text-center"
            id="why-choose-static-web-design"
          >
            <div className="col-12">
              <h2 className="text-center mt-4 top-para">
              Why Choose Static Web Design?              </h2>
              <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "100px",
                  margin: "20px auto",
                  opacity: "1",
                }}
              />

              <p className="top-para">
              A static website offers several advantages, making it a perfect solution for businesses seeking speed, security, and simplicity. Especially for businesses that require a fast, secure, and low-maintenance online presence, static web design is an ideal choice. Here's why:
              </p>
              
            </div>
            <div className="col-12">
              <div className="row mt-4">
                {designTypes.map((type, index) => (
                  <div key={index} className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="card d-flex flex-row align-items-center p-3">
                      <div className="me-3">{type.icon}</div>
                      <div>
                        {type.link ? (
                          <Link to={type.link}>{type.title}</Link>
                        ) : (
                          type.title
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="container py-5" id="static-web-development-services">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <h2 className="text-center mt-4 top-para">
                Our Static Web Development Services                </h2>
                <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "100px",
                    margin: "20px auto",
                    opacity: "1",
                  }}
                />

                <p className="lead mb-4">
                As a <b>top static web development company</b>, we offer a range of services to meet your business goals:
                </p>
                <ul className="text-start mb-4 top-para">
                  <li><b>Custom Static Web Design</b> We create fully customized, responsive designs that reflect your brand identity. Whether you're launching a new business or revamping your current website, our designs are built to engage users and drive conversions.</li>
                  <li><b>Responsive Static Web Development</b> Our website developers ensure your static site works seamlessly across all devices. From desktop to mobile, your website will look great and function perfectly.</li>
                  <li><b>SEO-Optimized Static Websites</b> We develop websites that are fully optimized for search engines, ensuring that your business ranks high on Google and other search engines, driving traffic to your site.</li>
                  <li><b>Static Website Maintenance & Support</b> While static websites are low-maintenance, our team provides ongoing support and updates when needed to keep your website running smoothly.</li>
                  <li><b>Fast and Secure Hosting Solutions</b> We offer secure hosting services for static websites, ensuring high-speed performance and reliability, allowing your business to operate without interruptions.
                  </li>
                </ul>
            
              </div>
              <div className="col-12 col-lg-6 mt-4 mt-lg-0 text-center">
                <img
                  src={web4}
                  alt="Static Web Design Services in India"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <section className="py-3 py-md-5 py-xl-8">
          <div className="container flex" id="why-works-with-bytefaze">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <img
                  src={webdesign}
                  alt="Static Web Developer in Ahmedabad, Gujarat, India"
                  className="img-fluid mt-1"
                  width={600}
                />
              </div>
              <div className="col-lg-6 mt-2">
                <div>
                  <h2 className="top-heading">
                  Why Work with Bytefaze ?                  </h2>
                  <hr
              style={{
                borderTop: "3px solid #ffc107",
                width: "100px",
                margin: "20px auto",
                opacity: "1",
              }}
            />
                   <ul className="top-para">
                    <li>
                      <b>Expert Website Developers</b>
                    </li>

                    <li>
                      <b>Comprehensive Static Web Design Services</b>
                    </li>

                    <li>
                      <b>Affordable Packages</b>
                    </li>

                    <li>
                      <b>Quick Delivery</b>
                    </li>
                    <li><b>Quick Delivery</b></li>
                  </ul>
                  
                </div>
              </div>
            </div>
          </div>
          <div id="custom-static-website">
            <div className="container mt-5 mb-5">
              <div className="col-12">
                <h2 className="text-center mt-4 top-para">
                Get Your Custom Static Website Today!                </h2>
                <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "100px",
                    margin: "20px auto",
                    opacity: "1",
                  }}
                />

                <p className="top-para">
                Ready to build a fast, secure, and reliable website? Partner with the best static web development company for your project. Contact us today to learn more about our static web designing services and how we can help you create an impactful online presence.
                </p>
              </div>
              <p className="top-para">Contact Bytefaze today for a free consultation and let our expert website developers bring your vision to life with our professional static web design services.</p>
              <div className="d-flex justify-content-center">
                  <Link to="/contact-us">
                    <button className="button-33 mb-2 col-12 col-lg-12 mt-3">Contact Now! </button>
                  </Link>
                </div>
            </div>
          </div>
          
          
        </section>
        
      </div>
    </Layout>
  );
};

export default StaticWebsite;