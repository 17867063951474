// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNnhNWsSj4IaJ63D1HR4QkD9aWBQdD9D4",
  authDomain: "bytefaze-fb2ac.firebaseapp.com",
  databaseURL: "https://www.bytefaze-fb2ac-default-rtdb.firebaseio.com",
  projectId: "bytefaze-fb2ac",
  storageBucket: "bytefaze-fb2ac.appspot.com",
  messagingSenderId: "516886396387",
  appId: "1:516886396387:web:1a0996a11ab1d77c3fb4d6",
  measurementId: "G-DHE4LDPF5D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth();
export const db = getFirestore(app)
export default app