import React, { useEffect, useState } from "react";
import "../../../Styles/sidebar.css";
import Layout from "../layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Dashloader from "../Dashloader";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

const UserDetails = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { id } = useParams(); // Get the document ID from the URL
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'ContactForms', id); // Use the appropriate collection name
        const docSnap = await getDoc(docRef);
       



        if (docSnap.exists()) {
          setData(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data found</div>;
  }

  const deleteform = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this form?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'ContactForms', id)); // Delete the document from Firestore
        alert('Form deleted successfully!');
        navigate('/dashboard'); // Redirect to the dashboard
      } catch (error) {
        console.error('Error deleting document:', error);
        alert('Failed to delete the form. Please try again.');
      }
    }
  };
  // console.log(form)

  return (
    <Layout>
      <div className="container mt-5">
        <div className="card">
          <div className="card-header h-50 d-flex justify-content-between">
            <h3>Details</h3>
            <button className="btn-danger border px-3" onClick={deleteform}>
              Delete
            </button>
          </div>
          {loader ? (
            <div className="admin-load">
              <div>
                <Dashloader />
              </div>
              ...Loading
              <div>
                <Dashloader />
              </div>
            </div>
          ) : (
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <strong>
                    First Name: &nbsp;&nbsp;{data?.firstName}&nbsp;
                    {data?.lastName}
                  </strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <strong>Email:&nbsp;&nbsp;{data?.email}</strong>
                </div>
                <div className="col-md-6 mt-3">
                  <strong>Phone Number:&nbsp;&nbsp;{data?.phoneNumber}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <strong>City:&nbsp;&nbsp;{data?.city}</strong>
                </div>
                <div className="col-md-6 mt-3">
                  <strong>Area:&nbsp;&nbsp;{data?.area}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <strong>Date:&nbsp;&nbsp;{data?.timestamp ? data?.timestamp.split(',')[0] : "N/A"}</strong>
                </div>
                <div className="col-md-6 mt-3">
                  <strong>Time:&nbsp;&nbsp;{data?.timestamp ? data?.timestamp.split(',')[1] : "N/A"}</strong>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12 mt-3">
                  <strong>Subject:&nbsp;&nbsp;{data?.subject}</strong>
                </div>
                <div className="col-md-12 mt-3">
                  <strong>Message:&nbsp;&nbsp;{data?.message}</strong>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default UserDetails;