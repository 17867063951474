import React, { useEffect, useState } from "react";
import "../../../Styles/sidebar.css";
import { FaGift, FaHandHoldingUsd, FaTruck, FaChartLine } from "react-icons/fa";
import { Link } from "react-router-dom";
import Dashloader from "../../Admin/Dashloader";
import Layout from "../../Admin/layout/Layout";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import dayjs from "dayjs";

const AdminDashboard = () => {
  const [contactForms, setContactForms] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 15;

  const [last24Hours, setLast24Hours] = useState(0);
  const [last48Hours, setLast48Hours] = useState(0);
  const [todayForms, setTodayForms] = useState(0);

  useEffect(() => {
    fetchContactForms();
  }, []);

  const fetchContactForms = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'ContactForms'));
      const formsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      
      // Sort the forms data by timestamp in descending order (latest first)
      const sortedForms = formsData.sort((a, b) => {
        const dateA = a.timestamp ? dayjs(a.timestamp, "M/D/YYYY, h:mm:ss A").valueOf() : 0;
        const dateB = b.timestamp ? dayjs(b.timestamp, "M/D/YYYY, h:mm:ss A").valueOf() : 0;
        return dateB - dateA;
      });

      setContactForms(sortedForms);

      // Calculate forms within last 24 and 48 hours, and forms submitted today
      calculateFormCounts(sortedForms);
    } catch (error) {
      console.error('Error fetching contact forms: ', error);
    }
  };

  const calculateFormCounts = (forms) => {
    const now = dayjs(); // Current date and time
    const todayDate = now.format('YYYY-MM-DD'); // Format today's date as YYYY-MM-DD
  
    const formsIn24Hours = forms.filter(form => 
      dayjs(form.timestamp, "M/D/YYYY, h:mm:ss A").isAfter(now.subtract(24, 'hours'))
    );
    
    const formsIn48Hours = forms.filter(form => 
      dayjs(form.timestamp, "M/D/YYYY, h:mm:ss A").isAfter(now.subtract(48, 'hours'))
    );
    
    // Filter forms based on the date only (not time)
    const todayForms = forms.filter(form => 
      dayjs(form.timestamp, "M/D/YYYY, h:mm:ss A").format('YYYY-MM-DD') === todayDate
    );
  
    setLast24Hours(formsIn24Hours.length);
    setLast48Hours(formsIn48Hours.length);
    setTodayForms(todayForms.length);
  };
  

  // Calculate the current items to display based on the current page
  const currentItems = contactForms.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  // Pagination functions
  const handlePrevClick = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setPage((prevPage) => Math.min(prevPage + 1, Math.ceil(contactForms.length / itemsPerPage)));
  };

  const totalPages = Math.ceil(contactForms.length / itemsPerPage);

  return (
    <Layout>
      <div className="container-fluid px-3">
        <div className="row g-3 my-2">
        <div className="col-md-3">
  <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
    <div>
      <h3 className="fs-2">{contactForms.length}</h3>
      <p className="fs-5">Total Forms</p>
    </div>
    <FaGift className="fs-1 primary-text border rounded-full secondary-bg p-3" />
  </div>
</div>

<div className="col-md-3">
  <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
    <div>
      <h3 className="fs-2">{last48Hours}</h3>
      <p className="fs-5">48 Hours</p>
    </div>
    <FaHandHoldingUsd className="fs-1 primary-text border rounded-full secondary-bg p-3" />
  </div>
</div>

<div className="col-md-3">
  <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
    <div>
      <h3 className="fs-2">{last24Hours}</h3>
      <p className="fs-5">24 Hours</p>
    </div>
    <FaTruck className="fs-1 primary-text border rounded-full secondary-bg p-3" />
  </div>
</div>

<div className="col-md-3">
  <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
    <div>
      <h3 className="fs-2">{todayForms}</h3>
      <p className="fs-5">Forms Today</p>
    </div>
    <FaChartLine className="fs-1 primary-text border rounded-full secondary-bg p-3" />
  </div>
</div>

        </div>

        {/* Table Section */}
        <div className="row my-5">
          <h3 className="fs-3 mb-3">All Data</h3>
          <div className="col">
            {loader ? (
              <div className="admin-load">
                <div>
                  <Dashloader />
                </div>
                ...Loading
                <div>
                  <Dashloader />
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table bg-white rounded shadow-sm table-hover w-100 ">
                  <thead>
                    <tr className="adtable mx-2 ">
                      <th scope="col">#</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">City</th>
                      <th scope="col">Msg</th>

                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
  {currentItems.map((element, i) => {
    const isToday = element?.timestamp ? 
      dayjs(element.timestamp, "M/D/YYYY, h:mm:ss A").format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') 
      : false;

    return (
      <tr key={i} className={isToday ? 'blink' : ''}>
        <th scope="row">{(page - 1) * itemsPerPage + i + 1}</th>
        <td>{element?.firstName}</td>
        <td>{element?.lastName}</td>
        <td>{element?.phoneNumber}</td>
        <td>{element?.city}</td>
        <td>{element?.message?.slice(0, 10)}...</td>
        <td>{element?.timestamp ? element?.timestamp.split(',')[0] : "N/A"}</td>
        <td>{element?.timestamp ? element?.timestamp.split(',')[1] : "N/A"}</td>
        <td>
          <Link to={`/dashboard/details/${element?.id}`}>
            <button className="btn-success border p-1">
              View More
            </button>
          </Link>
        </td>
      </tr>
    );
  })}
</tbody>

                </table>

                {/* Pagination */}
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                      <button
                        className="page-link"
                        onClick={handlePrevClick}
                        disabled={page === 1}
                      >
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${page === index + 1 ? "active" : ""}`}
                        onClick={() => setPage(index + 1)}
                      >
                        <button className="page-link">{index + 1}</button>
                      </li>
                    ))}
                    <li className={`page-item ${page === totalPages ? "disabled" : ""}`}>
                      <button
                        className="page-link"
                        onClick={handleNextClick}
                        disabled={page === totalPages}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
