import React from "react";
import Layout from "../../layout/Layouts";
import img1 from "../../../assets/WebpImages/web-design-ahmedabad.webp";
import { Link, useNavigate } from "react-router-dom";
import Card from '../Card'
import logo from "../../../assets/WebpImages/bytedark.webp";
//import { LazyLoadImage } from "react-lazy-load-image-component";
import Accordian from "../../homecomponents/Accordian.jsx";
const WebDesignAhmedabad = () => {
  const navigate = useNavigate();

  const navigateToContacts = () => {

    navigate('/contact-us');
  };
  const data = {
    tag: "Web Designing in Ahmedabad",
    description:
      "Get here details about Professional Web Designing Services offers by top Website Designing Company in Ahmedabad. Know Bytefaze Web Designer Cost",
    keywords:"Web designing, Web development, website designing company in Ahmedabad, Web development services, Website designers, web services, Web development company, Website Hosting, Custom web designing, Local web development company, web expert in Ahmedabad, Professional Web Designing Services, Top Website Designing Agency in Ahmedabad, Web Designer Cost in Ahmedabad",
    img: img1,
    altTag: "Web Designing Ahmedabad", //image Alt tag
    label: "Web Designing Ahmedabad",
    title:
      "Professional Web Designing, Development Services Ahmedabad",
    PageTitle: "Web Designing Ahmedabad",
    date: "01 May 2024",
    desc: (<p className="lead fw-bold">
      Bytefaze web solutions is leading Ahmedabad located website designing company have a vast experience of three years in providing innovative web solutions in all over India. We have expertise in developing websites that come true to the all aspects of clients.
      </p>
    ),
    img2: img1,
    points: (
      <>
        {/* point1 */}
        <div className="container w-75">
          <div className="row  item-align-center col-md-12 text-center">
            <table className="table border">
              <thead>
                <tr>
                  <th style={{ color: "var(--link-color)" }}>
                    Table of Contents
                  </th>
                </tr>
              </thead>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#web-designing-company-in-ahmedabad">
                    Web Designing Company in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#professional-web-designing-services">
                    Professional Web Designing Services
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#top-website-designing-agency-in-ahmedabad">
                    Top Web Designing Agency in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#local-web-designing-company-in-ahmedabad">
                    Local Web Designing Company in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#web-designer-cost-in-ahmedabad">
                    Web Designer Cost in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#comprehensive-web-designing-services-ahmedabad">
                  Creative Web Development & Designing Services
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#website-designing-process">Web Designing Process</a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#faq">FAQ's</a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <h2
          style={{ color: "var(--link-color)" }}
          className="pt-2"
          id="web-designing-company-in-ahmedabad"
        >
          Web Designing Company in Ahmedabad
        </h2>
        <p className="top-para">
        Bytefaze web solutions is a top website design and development company in India. Our company offers a team of experts or website designers who can deliver the best web solutions for your website in            <b>
             Ahmedabad, <Link to='/web-designing-in-surat'>Surat</Link>, Nadiad, <Link to='/web-designing-in-baroda'>Vadodara</Link>, <Link to='/web-designing-in-gandhinagar'>Ghandhinagar</Link>, <Link to='/web-designing-in-bhavnagar'>Bhavnagar</Link>, <Link to='/web-designing-in-jamnagar'>Jamnagar</Link>, <Link to='/web-designing-in-mehsana'>Mehsana</Link>, <Link to='/web-designing-in-morbi'>Morbi</Link>, <Link to='/web-designing-in-mumbai'>Mumbai</Link> Anand and <Link to='/web-designing-in-rajkot'>Rajkot</Link>.We provide our services to cover up city area especially Vatva, Narol, Naroda, Aslali, Kathwada, Dhamatvan, Rakhiyal and many more.
           
          </b>
          .
        </p>

        {/* point1 */}

        {/* point3 */}
        <h2
          style={{ color: "var(--link-color)" }}
          className="pt-2"
          id="professional-web-designing-services"
        >
          Professional Web Designing Services
        </h2>
        <div className="top-para">
          
     
        <p >
          Bytefaze web Solutions is a{" "}
          <Link style={{ color: "blue" }} to="/">
            <b>professional web designing services</b>
          </Link>{" "}
          provider from Ahmedabad. Our company has received positive reviews
          from our customers for our beautiful web design, efficient project
          completion, and support services. We are known for our custom website
          designing and knowledge in web design . We are also commended for our
          work strategy and use of latest technologies.{" "}
        </p>
        </div>
        <p className="top-para">
        With a staff of highly qualified web experts, Bytefaze is a reputable Web Designing company in Ahmedabad that prioritizes long-term business relationships by offering the best, most customized services.

        </p>
        {/* point3*/}
        <p className="top-para" id="professional">
          Professional Web Services, We offer
        </p>
        <ul className="top-para">
          <li>
            <b>UI/UX Design for Web Design</b>
          </li>
          <li>
            <b>Responsive Website Designing </b>
          </li>
          <li>
            <b>Custom Web Development</b>
          </li>
          <li>
            <b>E-commerce Web Designing</b>
          </li>
          <li>
            <b>Software Development</b>
          </li>
          <li>
            <b>Static / Dynamic Website Designing</b>
          </li>
          <li>
            <b>Mobile Application Development</b>
          </li>
        </ul>
        {/* point4*/}
        <h3
          style={{ color: "var(--link-color)" }}
          id="top-website-designing-agency-in-ahmedabad"
        >
          Top Web Designing Agency in Ahmedabad
        </h3>
        <div className="top-para">

   
        <p>
          Bytefaze Web Solutions is a{" "}
          <Link to="/website-designing">
            <b>top Websites designing agency in Ahmedabad</b>
          </Link>
          , known for creating the most responsive web designs. With excellence
          as our primary goal, we work hard to provide you with the greatest
          quality possible every time you do business with us.
        </p>
        </div>
        <p className="top-para">
          We develop and redesign new and existing websites. We offer customized
          services based on the needs and requirements of our customers. Our
          professional team offers the best web development services in
          Ahmedabad.
        </p>
        <h3
          style={{ color: "var(--link-color)" }}
          id="local-web-designing-company-in-ahmedabad"
        >
          Local web designing company in Ahmedabad
        </h3>
        <p className="top-para">
          Recognizing the pivotal role of websites in shaping online identities,
          ByteFaze ensures meticulous attention to detail in every aspect of{" "}
          <b>web design and development.</b> From captivating visuals to
          intuitive interfaces, our team meticulously crafts each element to
          resonate with your target audience. Whether you're a startup seeking
          to establish an online foothold or an established enterprise aiming to
          redefine digital presence, We offers tailored website solutions to
          meet your diverse needs. Our responsive web designs adapt flawlessly
          to any device, ensuring optimal user experiences across platforms.
        </p>
        <p className="top-para">
          What distinguishes ByteFaze as the preferred{" "}
          <b>website designing company in Ahmedabad</b> is our steadfast
          commitment to excellence and client satisfaction. We foster enduring
          partnerships by guiding clients through each stage of the process,
          providing comprehensive support to facilitate long-term success. Dive
          into our extensive portfolio and witness firsthand the transformative
          impact of ByteFaze's craftsmanship across various industries. From
          dynamic e-commerce platforms to corporate showcases, our portfolio
          epitomizes the caliber of work synonymous.
        </p>
        {/* point5*/}
        <h3
          style={{ color: "var(--link-color)" }}
          id="web-designer-cost-in-ahmedabad"
        >
          Web Designer Cost in Ahmedabad
        </h3>
        <p className="top-para">
          <b>Web designer cost in Ahmedabad</b> is decided on base of which technology and which type of website you want to build. In the market many web designer offers low cost website designing services but its never give impact as you need. Last but not the list, We are not a low cost web designer in Ahmedabad, but we are web developer who creates the website through coding not using free templates like others.
        </p>
        <section className="py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 d-flex justify-content-center fw-bold text-center" style={{ color: "red" }}>
              <h1>Know Your Website Design Cost</h1>
            </div>
           
            <div className="col-lg-12 d-flex justify-content-center" onClick={navigateToContacts}>
              <button className="rbutt">
              Get a Free Website Design Quote
              </button>
            </div>
          </div>

        </div>
      </section>
        <h3
          style={{ color: "var(--link-color)" }}
          id="comprehensive-web-designing-services-ahmedabad"
        >
          Creative Web Development & Designing Services
        </h3>
        <p className="top-para">
          At Bytefaze, we offer a wide range of{" "}
          <b>web designing services in Ahmedabad</b> to cater to different
          business requirements. Our services include:
        </p>
        <ul style={{color:"var(--link-color)"}}>
          <li>
            <h4>Custom Web Design</h4> Our <b>custom web design solutions</b>{" "}
            are perfect for businesses looking to create a unique online
            presence. We work closely with our clients to understand their
            vision and deliver a website that exceeds their expectations.
          </li>
          <li className="pt-3">
            <h4>E-Commerce Design</h4> Transform your online store with our{" "}
            <b>e-commerce web design services</b>. We build secure, scalable,
            and user-friendly e-commerce platforms that make it easy for
            customers to shop and for you to manage your business.
          </li>
          <li className="pt-3">
            <h4>UI/UX Design</h4> Our UI/UX design services focus on creating
            intuitive and engaging user interfaces that enhance the overall user
            experience. A well-designed interface can significantly improve user
            satisfaction and conversion rates.
          </li>
          <li className="pt-3">
            <h4>Website Redesign</h4> Modernize your existing website to enhance
            its performance and appeal.
          </li>
        </ul>
        <h5
          style={{ color: "var(--link-color)" }}
          id="website-designing-process"
          className="pt-3"
        >
          Web Designing Process - From Concept to Completion
        </h5>
        <p className="top-para">
          At Bytefaze, we follow a systematic approach to web designing in
          Ahmedabad. Our process includes:
        </p>
        <ol style={{color:"var(--link-color)"}}>
          <li className="pb-1">
            <b>Consultation:</b> Understanding your business needs and
            objectives.
          </li>
          <li className="pb-1">
            <b>Planning:</b> Creating a detailed project plan and timeline.
          </li>
          <li className="pb-1">
            <b>Design:</b> Developing wireframes and mockups based on your
            input.
          </li>
          <li className="pb-1">
            <b>Development:</b> Building the website using the latest
            technologies and best practices.
          </li>
          <li className="pb-1">
            <b>Testing:</b> Conducting thorough testing to ensure everything
            works perfectly.
          </li>
          <li className="pb-1">
            <b>Launch:</b> Deploying your website and making it live.
          </li>
          <li className="pb-1">
            <b>Support:</b> Providing ongoing maintenance and support to keep
            your website running smoothly.
          </li>
        </ol>
        <div id="faq">
          <Accordian />
        </div>
      </>
    ),
    lastp:
      "Ready to take your online presence to the next level ? Contact Bytefaze, the leading web design company in Ahmedabad, and let us help you create a website that truly represents your brand and engages your audience. Get in touch with us today to learn more about our web designing services and how we can help your business succeed online.",

    // "In today's digital world, having a strong online presence is essential for any local business. A well-designed website acts as your digital storefront, attracting new customers and keeping existing ones engaged. Here's a breakdown of website design services specifically tailored for local businesses:"

    Fieldtags: (
      <>
        <div className="container mt-1">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-wrap">
                <Link to="/" className="btn btn-outline-secondary m-1">
                  Web Designing Ahmedabad
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Best Web Designing Company in Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Web Development Company in Ahmedabad
                </Link>
                <Link
                  to="/responsive-website"
                  className="btn btn-outline-secondary m-1"
                >
                  Professional Web Designing Services
                </Link>
                <Link
                  to="/digital-marketing"
                  className="btn btn-outline-secondary m-1"
                >
                  Top Web Designing Agency in Ahmedabad
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Web Designer Cost in Ahmedabad
                </Link>
                <Link
                  to="/ecommerce-website"
                  className="btn btn-outline-secondary m-1"
                >
                  E-commerce Website Design Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Website Design Ahmedabad
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Website Designers in Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Ahmedabad Website Design Company
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Website Maker in Ahmedabad
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };

  return (
    <Layout
      title={data?.title}
      keywords={data?.keywords}
      description={data?.description}
      canonical={"https://www.bytefaze.com/website-designing"}
      url={"https://www.bytefaze.com/blog/web-designing-ahmedabad"}
      ldName={"Website Designing Ahmedabad"}
      ldImage={img1}
      >
      {/* <!-- Featured 1 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <div
            className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between"
            style={{ color: "var(--link-color)" }}
          >
            <div className="col-12 col-lg-7 order-1 order-lg-0">
              <div className="entry-header mb-3">
                <ul className="entry-meta list-unstyled d-flex mb-3">
                  <li>
                    <a
                      className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                      href="#"
                    >
                      {data?.label}
                    </a>
                  </li>
                </ul>
                <h1 className="display-3 fw-bold mb-4">{data?.PageTitle}</h1>
              </div>
              <div className="entry-footer">
                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                  <li>
                    <a
                      className="fs-7  text-decoration-none d-flex align-items-center"
                      href="#"
                    >
                      <svg
                        xmlns="https://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fillRule="currentColor"
                        className="bi bi-calendar3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                      <span
                        className="ms-2 fs-7"
                        style={{ color: "var(--link-color)" }}
                      >
                        {data?.date}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <a
                className="d-block bsb-hover-image overflow-hidden rounded"
                href="#"
              >
                <img 
                  className="img-fluid bsb-scale bsb-hover-scale-up"
                  src={img1}
                  alt="Website Design Ahmedabad"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Entry Content Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10">
          <div className="container">
            <div className="row justify-content-md-center gy-3 gy-xl-4">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.desc}
                {/* <p className="lead fw-bold">{data?.desc} <a href="#">emotional support</a> in a way that is healthy for both you and your partner.</p> */}
              </div>

              <div className="col-12 text-center">
                <img 
                  className="img-fluid my-4 rounded"
                  src={img1}
                  alt="Web Design Ahmedabad"
                />
              </div>

              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.points}
                <p>{data?.points2}</p>
                {/* <h2 style={{color:"var(--link-color)"}}>Offer Physical Affection</h2> */}
                {/* <img  className="img-fluid mt-3 mb-5 rounded" src={img1} alt=""/> */}
                <p>{data?.lastp}</p>
                {/* <p>Different people like different levels of physical affection at different times. If you are feeling unsure, communicate with your partner so you can learn what they are open to receiving and can provide it accordingly.</p> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Entry Meta --> */}
        <div className="pb-3 pb-md-5 pb-xl-10">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <span
                  className="me-1 fw-bold"
                  style={{ color: "var(--link-color)" }}
                >
                  {" "}
                  Tags:
                </span>
                <ul className="entry-meta list-unstyled d-inline-flex align-items-center justify-content-center mb-3">
                  {data.Fieldtags}
                </ul>
              </div>
            </div>
          </div>
        </div>


        {/* <!-- Author 2 - Bootstrap Brain Component --> */}
        <section className="bsb-author-2 py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1X">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">
                <div className=" border-light-subtle p-4 border">
                  <div className="row gy-3 align-items-center">
                    <div className="col-md-4">
                      <a
                        href="#"
                        className="bsb-hover-image d-block rounded overflow-hidden"
                      >
                        <img 
                          className="img-fluid author-avatar bsb-scale bsb-hover-scale-up"
                          src={logo}
                          alt="Website Designing Company"
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h6
                          className="card-subtitle mb-2  "
                          style={{ color: "var(--link-color)" }}
                        >
                          Article written by
                        </h6>
                        <h3 className="card-title mb-2">
                          <a
                            className="card-link  text-decoration-none"
                            href="#"
                          >
                            Bytefaze
                          </a>
                        </h3>
                        <p className="card-text mb-3">
                          We are skilled in all aspects of web design, from
                          planning and development to design and testing. I also
                          have experience in web development technologies.
                        </p>
                        <ul className="bsb-social-media nav m-0">
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
                            >
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-facebook"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                  <a className="nav-link " href="#">
                    <svg xmlns="https://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li> */}
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://x.com/Bytefaze"
                            >
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-twitter"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              href="https://www.linkedin.com/company/bytefaze/"
                            >
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1X ">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12">
                <h2
                  className="h4 mb-4 mb-md-5"
                  style={{ color: "var(--link-color)" }}
                >
                  Related Articles
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="container overflow-hidden px-4"> */}
          {/* <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}
          <Card />
          {/* </div> */}
          {/* </div> */}
        </section>

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2
                  className="display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis"
                  style={{ color: "var(--link-color)" }}
                >
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"
                      value=""
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default WebDesignAhmedabad;
