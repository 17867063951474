import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";

const WebDesignLondon = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in London ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in London",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Custom Website Design London",
      text: "In the competitive London market, a one-size-fits-all approach doesn’t work. That’s why we offer custom website design solutions that cater to the unique needs of your business. Whether you’re a startup looking to establish an online presence or an established company aiming to revamp your website, we create bespoke designs that align with your brand and business goals.",
    },
    {
      image: res2,
      heading: "Creative Website Developer",
      text: "Our team of creative website developers is passionate about crafting innovative websites that are not only visually appealing but also optimized for performance. We combine the latest design trends with cutting-edge technology to deliver websites that engage your audience and drive conversions. From e-commerce platforms to corporate websites, we bring creativity and technical expertise to every project.",
    },
    {
      image: web3,
      heading: "Award-Winning Web Design Agency",
      text: "As an award-winning agency, we pride ourselves on delivering exceptional web design services that have been recognized by industry leaders. Our commitment to quality, creativity, and client satisfaction has earned us accolades and a reputation as a trusted web design partner for businesses in London.",
    },
    
    // more content objects
  ];

  const ctaContent = {
    text: "Take the Next Step Towards Digital Excellence!",
    para : "Transform your online presence with our website design services in London. Partner with us, an award-winning web design agency, and take the first step towards digital success.",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 2;

  const textSections = [
    {
        index: 0,
        heading: "Best Website Designing Agency",
        text: "As a leading Award-Winning Web Design Agency based in India, we specialize in providing custom website design services tailored specifically for businesses in London. Our creative team of website developers is dedicated to transforming your vision into a unique, user-friendly, and responsive website that stands out in a crowded digital marketplace.",
      },
    {
      index: 2,
      heading: "Why Choose Us?",
      text: "<ul><li><b>Tailored Solutions: </b> We understand that every business is unique, and we offer customized design solutions to meet your specific needs.</li><li><b>Experienced Team:</b> Our developers and designers are industry veterans with years of experience in creating successful websites.</li><li><b>Award-Winning:</b> Our work speaks for itself. We’ve been recognized for our excellence in web design and development.</li><li><b>Client-Centric Approach:</b> Your satisfaction is our priority. We work closely with you to ensure your vision is realized.</li><li><b>Competitive Pricing</b> Get high-quality, custom website design services at competitive prices.</li></ul>",
    },
    
    // Add as many text sections as needed
  ];

  const linksData = [
    { text: "Custom Website Designing Agency in New York", url: "/website-design-development-in-newyork"  },
    { text: "Creative Web Developer in San Francisco", url: "/website-design-in-san-francisco" },
    { text: "Award Winning Web Design in San Joes", url: "/web-design-sanjoes" },
    { text: "Best Responsive Website Design", url: "/responsive-website" },
    { text: "Cost Effective Website Development Paris", url: "/web-design-paris" },
    
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Top Web Designers in London",
    "Web Development Company in UK",
    "Best Web Designing Services in London","Custom website Design London","Creative Website Developer in London","Web App Developement Agency in London", "Award Winning Web Design Agency in London","Digital Marketing Company in London"
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Web Design London | Custom Website Designing Agency "}
      keywords={
        "Website Design London, Custom website Design London, Creative Website Developer, Award Winning Web Design Agency "
      }
      description={
        "Bytefaze is a creative website designing company from India, offers cost-effective and award winning web design services in London, UK. Contact for know our web developing packages"
      }
      canonical={"https://www.bytefaze.com/web-design-development-in-london"}
      ldName={"Web Design & Development in London"}
      serviceType={"Website Designing & Development Service in London"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignLondon;
