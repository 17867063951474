import React, { useEffect } from "react";

import { Helmet } from 'react-helmet'
import { Link } from "react-router-dom";


function ErrorPage() {
    
    useEffect(() => {
        document.title = '404 - Page Not Found';
      }, []);

    return(
        <>
          <Helmet>
            <title>404 - Page Not Found</title>
          </Helmet>
       
<section className="py-3 py-md-5 min-vh-100 d-flex justify-content-center align-items-center " style={{backgroundColor: "grey" }}>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="text-center">
          <h2 className="d-flex justify-content-center align-items-center gap-2 mb-4">
            <span className="display-1 fw-bold">4</span>
            <i className="ri-error-warning-line text-danger display-2"></i>
            <span className="display-1 fw-bold" style={{ transform: "scaleX(-1)" }}>4</span>

          </h2>

          {/* <div class="main_wrapper d-flex justify-content-center">
  <div class="main">
    <div class="antenna">
      <div class="antenna_shadow"></div>
      <div class="a1"></div>
      <div class="a1d"></div>
      <div class="a2"></div>
      <div class="a2d"></div>
      <div class="a_base"></div>
    </div>
    <div class="tv">
      <div class="cruve">
        <svg
          xmlSpace="preserve"
          viewBox="0 0 189.929 189.929"
          xmlnsXlink="We specialize in making/www.w3.org/1999/xlink"
          xmlns  ="https://www.w3.org/2000/svg"
          version="1.1"
          class="curve_svg"
        >
  
        </svg>
      </div>
      <div class="display_div">
        <div class="screen_out">
          <div class="screen_out1">
            <div class="screen">
              <span class="notfound_text"> NOT FOUND</span>
            </div>
          </div>
        </div>
      </div>
      <div class="lines">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>
      <div class="buttons_div">
        <div class="b1"><div></div></div>
        <div class="b2"></div>
        <div class="speakers">
          <div class="g1">
            <div class="g11"></div>
            <div class="g12"></div>
            <div class="g13"></div>
          </div>
          <div class="g"></div>
          <div class="g"></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="base1"></div>
      <div class="base2"></div>
      <div class="base3"></div>
    </div>
  </div>
  <div class="text_404">
    <div class="text_4041">4</div>
    <div class="text_4042">0</div>
    <div class="text_4043">4</div>
  </div>
</div> */}



          <h3 className="h2 mb-2">Oops! You're lost.</h3>
          <p className="mb-5">The page you are looking for was not found.</p>
          <Link className="btn bsb-btn-5xl btn-dark rounded-pill px-5 fs-6 m-0" to="/" role="button">Back to Home</Link>
        </div>
      </div>
    </div>
  </div>
</section>
        </>
    );
}
export default ErrorPage;
