import React from "react";
//import { LazyLoadImage } from "react-lazy-load-image-component";

import Layout from "../layout/Layouts";
// import "../../Styles/Services.css";
import market from '../../assets/WebpImages/market.jpg'
import { Link } from "react-router-dom";

const WebDesignPune = () => {
  return (
    <Layout title={"Website Designing in Pune- Top Web Design Services in Pune"} 
    keywords={"Website Design Pune, Website Designing in Pune, Web Design Services in Pune, Top Website Design Company Pune, Web Design Pune, Website Designing Pune, best Website Design"}
     description={"Discover top website designing in Pune. Contact Bytefaze today to develop customized visually stunning and highly functional website design services in Hadapsar, Chakan, Shikrapur, Bhosari, Shirwal, Ranjangaon"}
     canonical={"https://www.bytefaze.com/web-designing-in-pune"}
     ldName={"Website Designing in Pune"}
     serviceType={"Website Designing Services in Pune"}
     ldImage={market}
     >

      <div style={{height:"250px"}} className="col-12  webdesign_bg">
        <h1 className="display-4 fw-bold">Website Designing in Pune</h1>
        <p className="text-center col-6 ">" Make Well-optimized Website that Turn Clicks Into Customers<br/> with Best Website Designing Company"</p>
        <div className='d-flex justify-content-center '>
             <Link to="/contact-us">
                  <button
                    className="button-85 mb-2 col-12 col-lg-12 mt-3"

                  >
                   Get A Quote
                  </button>
                </Link>
                </div>
      </div>
      <section className="py-3 py-md-5 py-xl-8">
          <div className="container">
          <div className="row align-items-center justify-content-center">
          <h2 id="best-website-designer-ahmedabad" className="mt-3"style={{color:"var(--link-color)"}}>Best Website Designing Company in Pune</h2>
              <div className="col-lg-6 mt-2">
          <div className="row justify-content-center ">
              
              <p className='top-para'>Welcome to Bytefaze, your premier choice for <Link to='/'><b>website designing in Pune</b></Link>. As an established <b>website design company from Ahmedabad</b>. With a rich history of delivering high quality website solutions, we are eager to extend our expertise to the dynamic business environment of <b>Pune</b>. We specialize in crafting unique, responsive website designs tailored to your brand’s identity, ensuring that your online presence stands out.</p>
            <p className='top-para'>Our services include <b>custom website designing</b>, ensuring that each website is uniquely tailored to reflect your brand’s identity and vision. We specialize in creating responsive layouts that guarantee a seamless experience across all devices. Our websites are designed with SEO best practices in mind to enhance your online visibility and attract more customers</p>
            <p className='top-para'>Additionally, we offer comprehensive e-commerce website solutions, providing user-friendly platforms to boost your online sales. Our efficient processes and experienced team enable us to deliver high quality websites  immediately, with ongoing support and <b>website maintenance services</b> to ensure your site remains updated and secure. We also provide <b>dynamic website designing, search engine optimization, and professional graphic design services.</b></p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <img 
                  src={market}
                  alt="Web Design Services in Pune"
                  className="img-fluid mt-1"
                  width={600}
                />
              </div>
          </div>
          <div>
            <p className='top-para'>Our approach involves a thorough consultation to understand your business needs, strategic planning, design and development, and a meticulous launch process followed by continuous support. Partner with us to elevate your online presence and achieve your business goals in Pune. We offers our services in nearby area of Pune includes <b>Hadapsar, Chakan, Shikrapur, Bhosari, Shirwal, Ranjangaon</b> </p>                  
            </div>
          </div>
          <div className="container w-100">
            <div className="row  item-align-center col-md-12 text-center pb-2">
              <table className="table border"> 
             <thead><tr>
              <th colSpan={3} style={{color:"var(--link-color)"}}>Market We Serve</th></tr>
              </thead>
              <tr>
                <td className='pt-1 pb-1'><Link to='/web-designing-in-gandhinagar'>Web Design Gandhinagar</Link>
                </td>
                <td className='pt-1 pb-1'><Link to='/blog/web-designing-ahmedabad'>Website Designing Ahmedabad</Link></td>
                <td className='pt-1 pb-1'><Link to='/web-designing-in-baroda'>Web Design Agency In Baroda</Link></td>
                </tr>
                <tr>
                  <td className='pt-1 pb-1'><Link to='/web-designing-in-rajkot'>Website Design Company In Rajkot</Link></td>
                  <td className='pt-1 pb-1'><Link to='/web-designing-in-surat'>Web Design Services Surat</Link></td>
                  <td className='pt-1 pb-1'><Link to='/web-designing-in-jamnagar'>Best Website Developer Jamnagar</Link></td>
                  </tr>
                  <tr>
                    <td className='pt-1 pb-1'><Link to='/web-designing-in-mehsana'>Top Web Designer Mehsana</Link></td>
                    <td className='pt-1 pb-1'><Link to='/web-designing-in-morbi'>Professional Web Designing Company In Morbi</Link></td>
                    <td className='pt-1 pb-1'><Link to='/web-designing-in-mumbai'>Digital Marketing Services Mumbai</Link></td>
                  </tr>
                  <tr>
                  <td className='pt-1 pb-1'><Link to='/web-designing-in-bhavnagar'>Responsive Web Design Solutions In Bhavnagar</Link></td>
                  <td className='pt-1 pb-1'><Link to='/web-designing-in-ankleshwar'>Website Design Services In Ankleshwar</Link></td>
                    <td className='pt-1 pb-1'>&nbsp;</td>
                  </tr>
                  
                  

              </table>
            </div>
            </div>
          </section>
      <div>

       
      </div>
     
    </Layout>
  );
};

export default WebDesignPune;