import React from "react";

//import { LazyLoadImage } from "react-lazy-load-image-component";


const Why = () => {
//   useEffect(() => {
//     AOS.init({ duration: 1000, delay: 500 });
//   }, []);
  return (
    <section style={{ background: "var(--footer-bg)" }} className="why-us" >
      <div className="container">
        <div className="row" data-aos="zoom-in">
          <div className="col-md-8 offset-md-2 mt-5">
            <h2 className=" text-center">Why Choose Us as Your Web Development Partner?</h2>
            <div className="mb-5 top-para">
           {/* <p> Choose Bytefaze as your <b>web designing and SEO partner</b> and experience the difference. We are dedicated to helping you create a strong online presence, drive more traffic to your website, and achieve your business goals. Contact us today to get started on your journey towards success.</p>*/}
</div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-up">
              <span>01</span>
              <h4>
              Experience and expertise
              </h4>
              <p> Our team has wide years of experience in web development, ensuring that your project is handled by professionals who know what they're doing.</p>
              {/* <img  className="img-fluid" src={AboutImg1} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-down">
              <span>02</span>
              <h4>
              Customized Solutions
              </h4>
              <p>We understand that every business is unique, and we work closely with you to create a website that perfectly aligns with your brand and goals.              </p>
              {/* <img  className="img-fluid" src={AboutImg2} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-up">
              <span>03</span>
              <h4>
              Modern Technologies
              </h4>
              <p>We stay up-to-date with the latest trends and technologies to ensure that your website is built using the most effective tools and techniques</p>
              {/* <img  className="img-fluid" src={AboutImg3} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-up">
              <span>04</span>
              <h4>
              Committed Team
              </h4>
              <p>Our team is dedicated to delivering exceptional results and providing excellent customer service throughout the entire development process.
              </p>
              {/* <img  className="img-fluid" src={AboutImg4} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-down">
              <span>05</span>
              <h4>
              Exceptional Customer Service
              </h4>
              <p>We prioritize open communication and timely delivery to ensure your complete satisfaction.</p>
              {/* <img  className="img-fluid" src={AboutImg5} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box">
              <span>06</span>
              <h4>
              Affordable Pricing
              </h4>
              <p>We offer competitive pricing without compromising on quality, making our services accessible to businesses of all1 sizes.  </p>
              {/* <img  className="img-fluid" src={AboutImg6} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Why);
