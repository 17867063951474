import React, { useState } from "react";
//import "../../Styles/styles.css";
import { Link } from "react-router-dom";
import axios from "axios";

const Progressive = () => {
  const [step, setStep] = useState(1);
  const [msg, setMsg] = useState("");
  const token = sessionStorage.getItem("token");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    email: "",
    phoneNumber: "",
    socialMediaLinks: "",
    address: {
      house: "",
      street: "",
      landmark: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
    },
    idProof: null,
    profile: null,
  });

  
  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };



    const handleChange = (e) => {
      const { name, value, files } = e.target;
      setFormData({
        ...formData,
        [name]: files ? files[0] : value,
        address: {
          ...formData.address,
          [name]: value,
        },
        
      });
    };


    

  const handleSubmit = async (e) => {
    e.preventDefault();

    const completeAddress = `${formData.address.house}, ${formData.address.street}, ${formData.address.landmark}, ${formData.address.pincode}, ${formData.address.city}, ${formData.address.state}, ${formData.address.country}`;

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      age: formData.age,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      socialMediaLinks: formData.socialMediaLinks,
      address: completeAddress,
      technology: "6672fdaece9a85d0ca47574f",
      idProof : formData.idProof ,
      profile: formData.profile,
    };

    try {
      const res = await axios.post(
        `https://www.bytefaze-backend.onrender.com/api/v1/formUser/register`,
        data,
        {

          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      
      );
      console.log (res.data, "cool");
      setMsg("Form submitted successfully!");
      alert("form submitted");
    } catch (error) {
      console.error("Error submitting form:", error);
      setMsg("Failed to submit form. Please try again.");
      // alert("some error occoured")
      console.log(error, "some error");
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-7">
                  <h2 className="fs-title">Personal Information:</h2>
                </div>
                <div className="col-5">
                  <h2 className="steps">Step 1 - 5</h2>
                </div>
              </div>
              <label className="fieldlabels">
                First Name: <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                name="firstName"
                onChange={handleChange}
                value={formData.firstName}
                placeholder="First Name"
              />
              <label className="fieldlabels">
                Last Name: <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                name="lastName"
                onChange={handleChange}
                value={formData.lastName}
                placeholder="Last Name"
              />
              <label className="fieldlabels">
                Age: <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
                placeholder="Enter Age"
              />
              <label className="fieldlabels">
                Email: <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Id"
              />
              <label className="fieldlabels">
                Phone Number: <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Contact No."
              />
              <label className="fieldlabels">
                Instagram Id: <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="url"
                name="socialMediaLinks"
                onChange={handleChange}
                value={formData.socialMediaLinks}
                placeholder="Paste Your Instagram Link Here"
              />
            </div>
            <input
              type="button"
              name="next"
              className="next action-button"
              value="Next"
              onClick={handleNext}
            />
          </fieldset>
        );
      case 2:
        return (
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-7">
                  <h2 className="fs-title">Address Information:</h2>
                </div>
                <div className="col-5">
                  <h2 className="steps">Step 2 - 5</h2>
                </div>
              </div>

              <label className="fieldlabels" htmlFor="house">
                House/Flat No. <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                id="house"
                name="house"
                value={formData.address.house}
                onChange={handleChange}
                placeholder="House/Flat No."
              />

              <label className="fieldlabels" htmlFor="street">
                Street <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                name="street"
                value={formData.address.street}
                onChange={handleChange}
                placeholder="Enter Street"
                id="street"
              />

              <label htmlFor="landmark" className="fieldlabels">
                Landmark <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Enter landmark"
                id="landmark"
                value={formData.address.landmark}
                onChange={handleChange}
                name="landmark"
              />
              <label htmlFor="pincode" className="fieldlabels">
                PinCode <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Enter PinCode"
                id="pincode"
                value={formData.address.pincode}
                onChange={handleChange}
                name="pincode"
              />

              <label htmlFor="city" className="fieldlabels">
                City <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Enter City"
                id="city"
                value={formData.address.city}
                onChange={handleChange}
                name="city"
              />
              <label htmlFor="state" className="fieldlabels">
                State <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Enter State"
                id="state"
                value={formData.address.state}
                onChange={handleChange}
                name="state"
              />

              <label htmlFor="country" className="fieldlabels">
                Country <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                placeholder="Your Country Name"
                id="country"
                value={formData.address.country}
                onChange={handleChange}
                name="country"
              />
            </div>
            <input
              type="button"
              name="next"
              className="next action-button"
              value="Next"
              onClick={handleNext}
            />
            <input
              type="button"
              name="previous"
              className="previous action-button-previous"
              value="Previous"
              onClick={handlePrevious}
            />
          </fieldset>
        );
      case 3:
        return (
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-7">
                  <h2 className="fs-title">Image Upload:</h2>
                </div>
                <div className="col-5">
                  <h2 className="steps">Step 3 - 5</h2>
                </div>
              </div>
              <label className="fieldlabels">Upload Your Photo:</label>
              <input type="file" name="profile" onChange={handleChange} />
              <label className="fieldlabels">
                Upload Your Document:(Eg. Aadhar Card, Pan Card, etc.)
              </label>
              <input type="file" name="idProof" onChange={handleChange} />
            </div>
            <input
              type="button"
              name="next"
              className="next action-button"
              value="Next"
              onClick={handleNext}
            />
            <input
              type="button"
              name="previous"
              className="previous action-button-previous"
              value="Previous"
              onClick={handlePrevious}
            />
          </fieldset>
        );
      case 4:
        return (
          <fieldset>
            <input
              type="button"
              name="next"
              className="next action-button"
              value="Submit"
              onClick={handleSubmit}
            />
            <input
              type="button"
              name="previous"
              className="previous action-button-previous"
              value="Previous"
              onClick={handlePrevious}
            />
          </fieldset>
        );
      case 5:
        return (
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-7">
                  <h2 className="fs-title">Finish:</h2>
                </div>
                <div className="col-5">
                  <h2 className="steps">Step 5 - 5</h2>
                </div>
              </div>
              <br />
              <br />
              <h2 className="purple-text text-center">
                <strong>SUCCESS !</strong>
              </h2>
              <br />
              <div className="row justify-content-center">
                <div className="col-3">
                  <div className="pro-image-container">
                    <img 
                      src="https://i.imgur.com/GwStPmg.png"
                      className="fit-image"
                      alt="Success"
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="row justify-content-center">
                <div className="col-7 text-center">
                  <h5 className="purple-text text-center">
                    You Have Successfully Signed Up
                  </h5>
                </div>
              </div>
            </div>
          </fieldset>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
            <div className="progress-card px-0 pt-4 pb-0 mt-3 mb-3">
              <h2 id="progress-heading">Sign Up Your User Account</h2>
              <p>Fill all form fields to go to the next step</p>
              <form id="msform">
                <ul id="progressbar">
                  <li className={step >= 1 ? "active" : ""} id="personal">
                    <strong>Personal</strong>
                  </li>
                  <li className={step >= 2 ? "active" : ""} id="personal">
                    <strong>Address</strong>
                  </li>
                  <li className={step >= 3 ? "active" : ""} id="image">
                    <strong>Id Proof</strong>
                  </li>
                  <li className={step >= 4 ? "active" : ""} id="intern">
                    <strong>Internship</strong>
                  </li>
                  <li className={step >= 5 ? "active" : ""} id="confirm">
                    <strong>Finish</strong>
                  </li>
                </ul>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${step * 20}%` }}
                  ></div>
                </div>
                <br />
                {renderStep()}
              </form>
              {msg && <p>{msg}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progressive;
